import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const PanelHeaderContainerWithStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

export interface PanelHeaderProps {
  children: ReactNode;
}

export default function PanelHeader({ children }: PanelHeaderProps) {
  return (
    <PanelHeaderContainerWithStyle>
      <Typography variant="caption">
        {children}
      </Typography>
    </PanelHeaderContainerWithStyle>
  );
}
