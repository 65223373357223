import React, { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useCortexDecoderKeyApi, { CortexKey } from '../../license/useCortexDecoderKeyApi';
import EditAppForm from './EditAppForm';
import useProjectOverviewApi from '../ProjectOverview/useProjectOverviewApi';
import useAppApi from '../../app/useAppApi';
import { ProjectConfig } from '../../project/useProjectApi';

export default function CreateAppView() {
  const api = useAppApi();
  const projectApi = useProjectOverviewApi();
  const cortexApi = useCortexDecoderKeyApi();
  const navigate = useNavigate();

  const [availableProjects, setAvailableProjects] = useState<ProjectConfig[]>([]);
  const [availableCortexDecoderKeys, setAvailableCortexDecoderKeys] = useState<CortexKey[]>([]);

  const getAppConfig = useCallback(async () => {
    setAvailableProjects((await projectApi.getAllProjects(null)).projects);
    setAvailableCortexDecoderKeys(await cortexApi.getKeys(null));
    return {};
  }, [cortexApi, projectApi]);

  const getCortexDecoderKeyInfo = useCallback(async (cortexDecoderKeyID: number) => {
    const keyData = await cortexApi.getKey(cortexDecoderKeyID);
    return keyData;
  }, [cortexApi]);

  const createAppConfig = useCallback(async (data: FieldValues) => {
    await api.createApp(data as any);
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="headlines.createApp" />
      <ResourceFormView
        actionName="createApp"
        Form={EditAppForm}
        FormProps={{
          availableProjects,
          availableCortexDecoderKeys,
          getCortexDecoderKeyInfo,
          createNewApp: true,
        }}
        fetch={getAppConfig}
        submit={createAppConfig}
        onSubmitted={() => navigate('/apps')}
      />
    </>
  );
}
