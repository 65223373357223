import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useRealmApi, { RealmUpdate } from '../../realm/useRealmApi';
import RealmForm from './RealmForm';
import useProjectOverviewApi from '../ProjectOverview/useProjectOverviewApi';
import { ProjectConfig } from '../../project/useProjectApi';

export default function RealmView() {
  const api = useRealmApi();
  const projectOverviewApi = useProjectOverviewApi();

  const navigate = useNavigate();

  const routerParams = useParams();
  const realmID = routerParams.realmID || '';
  const [availableProjects, setAvailableProjects] = useState<ProjectConfig[]>([]);

  const getRealmInfo = useCallback(async () => {
    setAvailableProjects((await projectOverviewApi.getAllProjects(null))?.projects || []);
    const responseData = await api.getRealm(realmID);
    return responseData;
  }, [api, projectOverviewApi, realmID]);

  const handleSubmit = useCallback(async (realmData: FieldValues) => {
    await api.updateRealm({ realmID, realmData: (realmData as RealmUpdate) });
  }, [api, realmID]);

  const handleDelete = useCallback(async () => {
    await api.deleteRealm(realmID);
    navigate('/realms');
  }, [api, navigate, realmID]);

  return (
    <>
      <DocumentTitle translationID="headlines.realmView" />
      <ResourceFormView
        actionName="realmView"
        Form={RealmForm}
        FormProps={{
          availableProjects,
          onRealmDelete: handleDelete,
        }}
        fetch={getRealmInfo}
        submit={handleSubmit}
        onSubmitted={() => navigate('/realms')}
      />
    </>
  );
}
