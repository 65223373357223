import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useApiClient,
} from '../apiForm/api';
import removeUndefinedObjectEntries, { parseAllCommaSeparatedStringsInObjectToArray, repairObject, updateObjectAtPath } from '../utils/apiObjectUtils';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';

const sanitizeData = (data: FieldValues) => {
  const repairedObject = repairObject(data);

  parseAllCommaSeparatedStringsInObjectToArray(repairedObject, 'features');
  parseAllCommaSeparatedStringsInObjectToArray(repairedObject, 'defaultPaymentConfigurations.[*].acceptedOriginTypes');
  parseAllCommaSeparatedStringsInObjectToArray(repairedObject, 'paymentConfigurations.[*].configurations.[*].acceptedOriginTypes');

  updateObjectAtPath(repairedObject, 'taxCategories.[*].validRates.[*].validFrom', (x: any) => (x || undefined));
  updateObjectAtPath(repairedObject, 'taxCategories.[*].validRates.[*].validUntil', (x: any) => (x || undefined));

  parseAllCommaSeparatedStringsInObjectToArray(repairedObject, 'fulfillmentConfigs.[*].shops');

  const messages = {
    manualDiscountCart: data.messages?.manualDiscountCart,
    manualDiscountLineItem: data.messages?.manualDiscountLineItem,
  };

  const fiscalizationProvider = {
    kind: data.fiscalizationProvider?.kind,
    baseURI: data.fiscalizationProvider?.baseURI || undefined,
  };

  let cleanedData:FieldValues = {
    ...repairedObject,
    messages,
    fiscalizationProvider,
  };

  delete cleanedData.id;
  cleanedData = removeUndefinedObjectEntries(cleanedData);

  return cleanedData;
};

export interface CheckoutParams {
  projectId: string,
  data: FieldValues,
  etag?: string,
}

const useApi = defineApi({
  getCheckoutConfig: async (
    client,
    { projectId }: { projectId: string },
  ) => {
    const response:any = await client({
      url: `${projectId}/checkout/config`,
      method: 'GET',
      validateStatus: (status) => isSuccessfulOrCustomResponse(status, [404]),
    });
    if (response.status === 404) return [{ id: projectId }, undefined];
    return [response.data, response.headers.etag];
  },

  updateCheckoutConfig: async (client, { projectId, data, etag }: CheckoutParams) => {
    const cleanedData = sanitizeData(data);

    await client({
      url: `${projectId}/checkout/config`,
      method: 'PUT',
      data: cleanedData,
      headers: {
        'If-Match': etag,
      },
    });
  },
});

export default function useProjectApi() {
  const client = useApiClient({ basePath: '' });
  return useApi(client);
}
