import { useEffect, useRef } from 'react';
import forOwn from 'lodash/forOwn';
import isEqual from 'lodash/isEqual';
import {
  useForm,
  UseFormProps,
  FieldValues,
  ErrorOption,
} from 'react-hook-form';

export interface UseEnhancedFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends UseFormProps<TFieldValues, TContext> {
  errors?: Record<string, ErrorOption>
}

export default function useEnhancedForm({
  errors,
  ...options
}: UseEnhancedFormProps = {}) {
  const form = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    ...options,
  });

  const { clearErrors, setError } = form;

  // Only update internal error state when the passed errors really do change
  const errorsRef = useRef<Record<string, ErrorOption>>();

  useEffect(() => {
    if (isEqual(errorsRef.current, errors)) return;
    errorsRef.current = errors;

    clearErrors();
    forOwn(errors, (error, field) => {
      setError(field, error);
    });
  }, [errors, clearErrors, setError]);

  return form;
}
