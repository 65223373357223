import React from 'react';
import Stack from '@mui/material/Stack';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

const DEFAULT_VALUES: any = {
};

interface CheckoutFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
}

export default function VposForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.vpos')}
        sublegend={t('checkoutConfig.sublegend.vpos')}
      >
        <TextField
          control={control}
          name="vpos.completionURL"
          disabled={readonly}
          label={t('checkoutConfig.label.vpos.completionURL')}
        />
        <TextField
          control={control}
          name="vpos.placementURL"
          disabled={readonly}
          label={t('checkoutConfig.label.vpos.placementURL')}
        />
        <TextField
          control={control}
          name="vpos.username"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.vpos.username')}
        />
        <TextField
          control={control}
          name="vpos.password"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.vpos.password')}
        />
        <TextField
          control={control}
          name="vpos.token"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.vpos.token')}
        />
        <TextField
          control={control}
          name="vpos.timeout"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.vpos.timeout')}
          helperText={t('checkoutConfig.helperText.vpos.timeout')}
        />
        <CheckboxField
          control={control}
          name="vpos.fallbackToLocalCompletion"
          disabled={readonly}
          label={t('checkoutConfig.label.vpos.fallbackToLocaleCompletion')}
        />
        <CheckboxField
          control={control}
          name="vpos.logRequests"
          disabled={readonly}
          label={t('checkoutConfig.label.vpos.logRequests')}
        />
        <TextField
          control={control}
          name="vpos.clientCert"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.vpos.clientCert')}
        />
        <TextField
          control={control}
          name="vpos.clientKey"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.vpos.clientKey')}
        />
      </ExpandableFieldset>

      <ExpandableFieldset
        legend={t('checkoutConfig.legend.vposShopConfigs')}
      >
        <ArrayWrapper
          control={control}
          name="vpos.shopConfigs"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.vposConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.vposConfig.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].shopID`}
                disabled={readonly}
                label={t('checkoutConfig.label.vpos.shopID')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].completionURL`}
                disabled={readonly}
                label={t('checkoutConfig.label.vpos.completionURL')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].placementURL`}
                disabled={readonly}
                label={t('checkoutConfig.label.vpos.placementURL')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].username`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.vpos.username')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].password`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.vpos.password')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].token`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.vpos.token')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].timeout`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.vpos.timeout')}
                helperText={t('checkoutConfig.helperText.vpos.timeout')}
              />
              <CheckboxField
                control={control}
                name={`vpos.shopConfigs.[${index}].fallbackToLocalCompletion`}
                disabled={readonly}
                label={t('checkoutConfig.label.vpos.fallbackToLocaleCompletion')}
              />
              <CheckboxField
                control={control}
                name={`vpos.shopConfigs.[${index}].logRequests`}
                disabled={readonly}
                label={t('checkoutConfig.label.vpos.logRequests')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].clientCert`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.vpos.clientCert')}
              />
              <TextField
                control={control}
                name={`vpos.shopConfigs.[${index}].clientKey`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.vpos.clientKey')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
