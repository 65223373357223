import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import { useResourceTranslator } from '../apiForm/resource';

type SidebarMenuItemContentProps = {
  linkText?: string,
  translationId?: string,
  icon: React.ReactNode,
  disabled?: boolean,
};

const styles = () => ({
  menuItem: {
    padding: 0,
    height: 'auto',
    display: 'flex',
  },
});

export default function SidebarMenuItemContent({
  linkText = '',
  translationId,
  icon,
  disabled,
}: SidebarMenuItemContentProps) {
  const classes = styles();

  const t = useResourceTranslator();
  const listItemText = linkText || t(translationId) || '';

  return (
    <ListItem disablePadding style={classes.menuItem}>
      <ListItemButton disabled={disabled}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={<Typography>{listItemText}</Typography>}
        />
      </ListItemButton>
    </ListItem>
  );
}
