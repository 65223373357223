import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import { ProjectConfig } from '../../project/useProjectApi';
import useRealmApi from '../../realm/useRealmApi';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useProjectOverviewApi from '../ProjectOverview/useProjectOverviewApi';
import RealmForm from './RealmForm';

export default function ProjectView() {
  const api = useRealmApi();
  const projectOverviewApi = useProjectOverviewApi();

  const navigate = useNavigate();

  const routerParams = useParams();
  const clonedRealmID = routerParams.realmID || '';

  const realmID = useRef<string | undefined>(undefined);
  const [availableProjects, setAvailableProjects] = useState<ProjectConfig[]>([]);

  const fetch = useCallback(async () => {
    setAvailableProjects((await projectOverviewApi.getAllProjects(null))?.projects || []);
    if (!clonedRealmID) {
      return {};
    }

    const responseData = await api.getRealm(clonedRealmID);
    responseData.id = '';
    responseData.name = '';
    return responseData;
  }, [api, clonedRealmID, projectOverviewApi]);

  const createProject = useCallback(async (data:any) => {
    const newRealm = await api.createRealm(data);
    realmID.current = newRealm.data.id;
  }, [api]);

  const navigateToProjectPage = useCallback(() => {
    if (!realmID.current) {
      navigate('/realms');
    }
    navigate(`/realms/${realmID.current}`);
  }, [navigate]);

  return (
    <>
      <DocumentTitle translationID="headlines.newProjectView" />
      <ResourceFormView
        actionName="newRealmView"
        Form={RealmForm}
        FormProps={{
          createNewRealm: true,
          availableProjects,
        }}
        fetch={fetch}
        submit={createProject}
        onSubmitted={() => navigateToProjectPage()}
      />
    </>
  );
}
