import { AxiosError } from 'axios';
import { err, ok } from 'neverthrow';
import {
  ApiValidationErrorData,
  FormValidationErrors,
  FormValidationResult,
} from './validation';

const VALIDATION_ERROR_STATUS_CODE = 422;

function mapValidationErrors(data: ApiValidationErrorData) {
  const errors: FormValidationErrors = {};
  data.error.validationErrors.forEach(({ field, category }) => {
    if (!errors[field]) {
      errors[field] = { types: {} };
    }
    errors[field].types[category] = true;
  });
  return errors;
}

export default async function withFormValidation<
  T,
>(valueOrPromise: T | Promise<T>): Promise<FormValidationResult<T>> {
  try {
    return ok(await valueOrPromise);
  } catch (e) {
    if (
      e instanceof AxiosError
      && e.response?.status === VALIDATION_ERROR_STATUS_CODE
    ) {
      return err(mapValidationErrors(e.response.data));
    }
    throw e;
  }
}
