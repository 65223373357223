import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopy from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../../DataDisplay/SortableTable';
import { useResourceTranslator } from '../../apiForm/resource';
import './RealmOverview.css';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useRealmApi from '../../realm/useRealmApi';

export default function RealmOverview() {
  const api = useRealmApi();
  const t = useResourceTranslator();
  const navigate = useNavigate();

  const [realms, setRealms] = useState<SortableTableDataPoint[]>();
  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      isNumeric: false,
      disablePadding: true,
      displayName: t('realmOverview.tableColumns.id') || '',
    },
    {
      key: 'name',
      isNumeric: false,
      disablePadding: false,
      displayName: t('realmOverview.tableColumns.name') || '',
    },
  ], [t]);

  const fetch = useCallback(async () => {
    setRealms((await api.getRealms(null))?.realms || []);
  }, [api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <DocumentTitle translationID="headlines.realmOverview" />
      <div className="button-container">
        <Button onClick={() => navigate('new')}>
          <AddIcon />
          {t('realmOverview.createRealmButton')}
        </Button>
      </div>
      <Paper>
        <SortableTable
          data={realms || []}
          columnsMetadata={headCells}
          title={t('realmOverview.tableHeader') || ''}
          filterPlaceholder={t('realmOverview.tableFilterPlaceholder')}
          rowButtons={[
            {
              icon: <EditIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(row.id); },
              tooltip: 'editRealm',
            },
            {
              icon: <ContentCopy fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`new/${row.id}`); },
              tooltip: 'cloneRealm',
            },
          ]}
        />
      </Paper>
    </>
  );
}
