import {
  defineApi,
  useProjectSpecificApiClient,
} from '../apiForm/api';

export enum CheckoutDeviceType {
  SCO = 'sco',
  GATEKEEPER = 'gatekeeper',
  POS = 'pos',
  'SCO-NG' = 'sco-ng',
}

const useApi = defineApi({
  getAvailableDeviceTypes: (client) => client.get<CheckoutDeviceType[]>('/available-types').then((resp) => resp.data),
  updateAvailableDeviceTypes: (client, data: string[]) => client.put('/available-types', data),
});

export default function useCheckoutDeviceApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices' });
  return useApi(client);
}
