import React from 'react';
import Stack from '@mui/material/Stack';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

const DEFAULT_VALUES: any = {
};

interface CheckoutFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
}

export default function CustomerPropertiesLookupForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.customerPropertiesLookups')}>
        <ArrayWrapper
          control={control}
          name="customerPropertiesLookups"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.customerPropertiesLookup.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.customerPropertiesLookup.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`customerPropertiesLookups.[${index}].type`}
                disabled={readonly}
                label={t('checkoutConfig.label.customerPropertiesLookups.type')}
              />
              <TextField
                control={control}
                name={`customerPropertiesLookups.[${index}].serviceURL`}
                disabled={readonly}
                label={t('checkoutConfig.label.customerPropertiesLookups.serviceURL')}
              />
              <TextField
                control={control}
                name={`customerPropertiesLookups.[${index}].basicAuthUser`}
                optional
                disabled={readonly}
                label={t('checkoutConfig.label.customerPropertiesLookups.basicAuthUser')}
              />
              <TextField
                control={control}
                name={`customerPropertiesLookups.[${index}].basicAuthPassword`}
                optional
                disabled={readonly}
                label={t('checkoutConfig.label.customerPropertiesLookups.basicAuthPassword')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
