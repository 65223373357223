import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

type PageLayoutProps = {
  children: React.ComponentType
};

const drawerWidth = 240;

export default function PageLayout({ children }: PageLayoutProps) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Topbar drawerWidth={drawerWidth} onDrawerToggle={handleDrawerToggle} />
      <Sidebar mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* This empty Toolbar is used so that the main content is not behind
        the Toolbar used in the Topbar component.
        (https://mui.com/material-ui/react-app-bar/#fixed-placement) */}
        <>
          <Toolbar />
          {children}
        </>
      </Box>
    </Box>
  );
}
