import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useAppApi from '../../app/useAppApi';
import useCortexDecoderKeyApi, { CortexKey } from '../../license/useCortexDecoderKeyApi';
import EditAppForm from './EditAppForm';
import { ProjectConfig } from '../../project/useProjectApi';
import useProjectOverviewApi from '../ProjectOverview/useProjectOverviewApi';

export default function EditAppView() {
  const api = useAppApi();
  const projectApi = useProjectOverviewApi();
  const cortexApi = useCortexDecoderKeyApi();
  const navigate = useNavigate();

  const { appID } = useParams();

  const [etag, setEtag] = useState<string | null>(null);
  const [availableProjects, setAvailableProjects] = useState<ProjectConfig[]>([]);
  const [availableCortexDecoderKeys, setAvailableCortexDecoderKeys] = useState<CortexKey[]>([]);

  const getCortexDecoderKeyInfo = useCallback(async (cortexDecoderKeyID: number) => {
    const keyData = await cortexApi.getKey(cortexDecoderKeyID);
    return keyData;
  }, [cortexApi]);

  const getAppConfig = useCallback(async () => {
    if (!appID) return null;

    setAvailableProjects((await projectApi.getAllProjects(null)).projects);
    setAvailableCortexDecoderKeys(await cortexApi.getKeys(null));

    const { data, etag: responseEtag } = await api.getApp(appID);
    setEtag(responseEtag || null);
    return data;
  }, [api, appID, cortexApi, projectApi]);

  const handleSubmit = useCallback(async (data: any) => {
    if (!appID || !etag) return;
    await api.updateApp({ appID, appData: data, etag });
  }, [api, appID, etag]);

  return (
    <>
      <DocumentTitle translationID="headlines.editApp" />
      <ResourceFormView
        actionName="editApp"
        Form={EditAppForm}
        FormProps={{
          availableProjects,
          availableCortexDecoderKeys,
          getCortexDecoderKeyInfo,
        }}
        fetch={getAppConfig}
        submit={handleSubmit}
        onSubmitted={() => navigate('/apps')}
      />
    </>
  );
}
