import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopy from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import useProjectOverviewApi, { ProjectOverviewData } from './useProjectOverviewApi';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../../DataDisplay/SortableTable';
import { useResourceTranslator } from '../../apiForm/resource';
import { addHost } from '../../utils/urls';
import './ProjectOverview.css';
import DocumentTitle from '../../utilComponents/DocumentTitle';

export default function ProjectOverview() {
  const api = useProjectOverviewApi();
  const t = useResourceTranslator();
  const navigate = useNavigate();

  const [projects, setProjects] = useState<SortableTableDataPoint[]>();
  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      isNumeric: false,
      disablePadding: true,
      displayName: t('projectOverview.tableColumns.id') || '',
    },
    {
      key: 'name',
      isNumeric: false,
      disablePadding: false,
      displayName: t('projectOverview.tableColumns.name') || '',
    },
  ], [t]);

  const fetch = useCallback(async () => {
    const receivedProjects: ProjectOverviewData = await api.getAllProjects({});
    setProjects(receivedProjects?.projects || []);
  }, [api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const redirectToRetailerPortal = useCallback((projectId: string) => {
    const retailerPortalUrl = addHost(window.location, `/${projectId}`, 'retailer');
    window.location.assign(retailerPortalUrl);
  }, []);

  return (
    <>
      <DocumentTitle translationID="headlines.projectOverview" />
      <div className="button-container">
        <Button onClick={() => navigate('/projects/new')}>
          <AddIcon />
          {t('projectOverview.createProjectButton')}
        </Button>
      </div>
      <Paper>
        <SortableTable
          data={projects || []}
          columnsMetadata={headCells}
          title={t('projectOverview.tableHeader') || ''}
          filterPlaceholder={t('projectOverview.tableFilterPlaceholder')}
          rowButtons={[
            {
              icon: <EditIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`/projects/${row.id}`); },
              tooltip: 'editProject',
            },
            {
              icon: <ContentCopy fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`/projects/new/${row.id}`); },
              tooltip: 'cloneProject',
            },
            {
              icon: <DesktopWindowsOutlinedIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { redirectToRetailerPortal(row.id); },
              tooltip: 'openProjectRetailerPortal',
            },
          ]}
        />
      </Paper>
    </>
  );
}
