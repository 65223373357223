export const safeStringifyJSON = (value? : any) : string => {
  if (!value) {
    return '';
  }
  return JSON.stringify(value, null, 2);
};

export const safeParseJSON = (value? : string) : any => {
  if (!value) {
    return undefined;
  }
  return JSON.parse(value);
};

export const arrayToCommaSeparatedString = (values: string[] | undefined): string => {
  if (!values || values.length === 0) {
    return '';
  }
  return values.reduce((prev, curr) => `${prev}, ${curr}`);
};

export const commaSeparatedStringToArray = (value: string): string[] => {
  if (!value) { return []; }

  return [...new Set(value.split(',').map((v) => v.trim()).filter((v) => v !== ''))];
};

export const safeCommaSeparatedStringToArray = (value: string | string[]): string[] => (
  typeof value === 'string' ? commaSeparatedStringToArray(value) : value
);
