import styled from '@mui/material/styles/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFormProps } from '../../apiForm/form';
import ViewTable from '../../DataDisplay/ViewTable/ViewTable';
import ViewTableRow from '../../DataDisplay/ViewTable/ViewTableRow';
import { parseDate, parseDatetime } from '../../utils/datetimeUtils';

const NoneBreakingSpan = styled('span')(() => ({
  whiteSpace: 'nowrap',
}));

const DEFAULT_VALUES: any = {};

interface ViewCortexDecoderKeyFormProps extends CustomFormProps {}

export default function ViewCortexDecoderKeyForm({
  defaultValues = DEFAULT_VALUES,
}: ViewCortexDecoderKeyFormProps) {
  const { t } = useTranslation();

  return (
    <ViewTable headline={t('cortexDecoderKeys.details')}>
      <ViewTableRow
        title={<NoneBreakingSpan>{t('form.labels.id')}</NoneBreakingSpan>}
        value={defaultValues.id}
      />
      <ViewTableRow
        title={<NoneBreakingSpan>{t('form.labels.name')}</NoneBreakingSpan>}
        value={defaultValues.name}
      />
      <ViewTableRow
        title={<NoneBreakingSpan>{t('form.labels.customerID')}</NoneBreakingSpan>}
        value={defaultValues.customerID}
      />
      <ViewTableRow
        title={<NoneBreakingSpan>{t('form.labels.licenseKey')}</NoneBreakingSpan>}
        value={<span style={{ wordBreak: 'break-all' }}>{defaultValues.licenseKey}</span>}
      />
      <ViewTableRow
        title={<NoneBreakingSpan>{t('form.labels.validUntil')}</NoneBreakingSpan>}
        value={parseDate(defaultValues.validUntil)}
      />
      <ViewTableRow
        title={<NoneBreakingSpan>{t('form.labels.updatedAt')}</NoneBreakingSpan>}
        value={parseDatetime(defaultValues.updatedAt)}
      />
    </ViewTable>
  );
}
