import React from 'react';
import ListItem from '@mui/material/ListItem';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/material/styles/createTheme';
// @ts-ignore
import { ReactComponent as Logo } from '../images/icon.svg';
import SnabbleMenuProjectSelector from '../utilComponents/SnabbleMenuProjectSelector';

type SidebarHeaderProps = {
  isMobile?: boolean,
};

const styles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: '4px solid #ffc400',
    margin: 0,
    height: '100%',
  },
  containerMobile: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down(700)]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  projectName: {
    marginLeft: theme.spacing(2),
  },
});

const selectTheme = (classes: any, isMobile: boolean | undefined) => {
  if (isMobile) return classes.containerMobile;
  return classes.container;
};

export default function SidebarHeader({ isMobile }: SidebarHeaderProps) {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <ListItem style={selectTheme(classes, isMobile)}>
      <Logo
        style={{
          position: 'absolute',
          left: '24px',
        }}
      />
      <SnabbleMenuProjectSelector />
    </ListItem>
  );
}
