import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export interface ViewTableRowProps {
  title: ReactNode;
  value: ReactNode;
  titleProps?: object;
  valueProps?: object;
}

export default function ViewTableRow({
  title,
  value,
  titleProps,
  valueProps,
}: ViewTableRowProps) {
  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" {...titleProps}>
        {title}
      </StyledTableCell>
      <StyledTableCell {...valueProps}>
        {value}
      </StyledTableCell>
    </StyledTableRow>
  );
}
