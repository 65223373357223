import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useResourceTranslator } from '../resource';
import ProgressButton, { ProgressButtonProps } from './ProgressButton';

export interface ConfirmButtonProps extends ProgressButtonProps {
  actionName?: string;
  onConfirm?: () => void;
  onDecline?: () => void;
  dialogTitle?: string;
  dialogBody?: string;
}

export default function ConfirmButton({
  actionName = 'default',
  children,
  onConfirm,
  onDecline,
  dialogTitle,
  dialogBody,
  ...props
}: ConfirmButtonProps) {
  const t = useResourceTranslator();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleConfirm = () => {
    onConfirm?.();
    setDialogOpen(false);
  };

  const handleDecline = () => {
    onDecline?.();
    setDialogOpen(false);
  };

  const handleButtonClick = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Dialog open={dialogOpen} maxWidth="xs">
        <DialogTitle>
          {dialogTitle ?? t(`confirmation.actions.${actionName}.title`)}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {dialogBody ?? t(`confirmation.actions.${actionName}.body`)}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleDecline}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <ProgressButton
        onClick={handleButtonClick}
        {...props}
      >
        {children || t(`actions.${actionName}`)}
      </ProgressButton>
    </>
  );
}
