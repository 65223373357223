import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

const TOKEN_NAME: string = 'jwt_token';

const getCookieValue = (a: string = TOKEN_NAME): string | undefined => {
  if (process.env.REACT_APP_TOKEN) {
    return process.env.REACT_APP_TOKEN;
  }

  const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

const isExpired = (token: any) => {
  if (token?.exp) {
    const expires = moment(token.exp * 1000);
    return moment().isSameOrAfter(expires);
  }
  return false;
};

export const decode = (token: string) => {
  try {
    const decodedToken = jwtDecode(token);
    if (isExpired(decodedToken)) {
      return null;
    }
    return decodedToken;
  } catch (err) {
    return null;
  }
};

const nullToken = {
  raw: '',
  grants: {},
};

export const getToken = () => {
  const rawCookie = getCookieValue();
  if (!rawCookie) {
    return nullToken;
  }
  const grants = decode(rawCookie);
  if (!grants) {
    return nullToken;
  }
  return {
    raw: rawCookie,
    grants,
  };
};
