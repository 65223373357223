import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../../DataDisplay/SortableTable';
import { useResourceTranslator } from '../../apiForm/resource';
import './AppsOverview.css';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useAppApi from '../../app/useAppApi';

function TableRowEntries({ rowData }: any) {
  return (
    <>
      <TableCell align="left" width="50%">
        {rowData.id}
      </TableCell>
      <TableCell align="left" width="50%">
        {rowData.projects?.length || 0}
      </TableCell>
    </>
  );
}

export default function AppsOverview() {
  const api = useAppApi();
  const t = useResourceTranslator();
  const navigate = useNavigate();

  const [apps, setApps] = useState<SortableTableDataPoint[]>();
  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      isNumeric: false,
      disablePadding: false,
      displayName: t('apps.tableColumns.id') || '',
    },
    {
      key: 'projectsLength',
      isNumeric: false,
      disablePadding: false,
      displayName: t('apps.tableColumns.projectsLength') || '',
    },
  ], [t]);

  const fetch = useCallback(async () => {
    setApps(await api.getApps(null) || []);
  }, [api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <DocumentTitle translationID="headlines.appsOverview" />
      <div className="button-container">
        <Button onClick={() => navigate('new')}>
          <AddIcon />
          {t('apps.createAppButton')}
        </Button>
      </div>
      <Paper>
        <SortableTable
          data={apps || []}
          columnsMetadata={headCells}
          CustomRowContent={TableRowEntries}
          title={t('apps.tableHeader') || ''}
          filterPlaceholder={t('apps.tableFilterPlaceholder')}
          rowButtons={[
            {
              icon: <VisibilityIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`${row.id}`); },
              tooltip: 'viewApp',
            },
            {
              icon: <EditIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`${row.id}/edit`); },
              tooltip: 'editApp',
            },
          ]}
        />
      </Paper>
    </>
  );
}
