/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomFormProps } from '../../apiForm/form';
import { AppVersion, Link } from '../../app/useAppApi';
import ViewTable from '../../DataDisplay/ViewTable/ViewTable';
import ViewTableRow from '../../DataDisplay/ViewTable/ViewTableRow';
import { parseDate, parseDatetime } from '../../utils/datetimeUtils';

const DEFAULT_VALUES: any = {};

const LinkSpan = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

interface ViewAppFormProps extends CustomFormProps {}

export default function ViewAppForm({
  defaultValues = DEFAULT_VALUES,
}: ViewAppFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <ViewTable headline={t('apps.details')}>
          <ViewTableRow title={t('apps.id')} value={defaultValues.id} />

          {defaultValues.projects?.length > 0 && (
            <ViewTableRow
              title={t('apps.projects')}
              value={(
                <ul>
                  {defaultValues.projects.map((project: string) => (
                    <li key={`${project}`}>
                      <LinkSpan onClick={() => navigate(`/projects/${project}`)}>
                        {project}
                      </LinkSpan>
                    </li>
                  ))}
                </ul>
                )}
            />
          )}

          {defaultValues.killedVersions?.length > 0 && (
            <ViewTableRow
              title={t('apps.killedVersions')}
              value={(
                <ul>
                  {defaultValues.killedVersions.map((version: AppVersion) => (
                    <li key={`${version.version}${version.platform}`}>
                      {version.lessThan && '< '}
                      {version.version}
                      {' '}
                      {version.platform}
                    </li>
                  ))}
                </ul>
                )}
            />
          )}

          {defaultValues.links?.length > 0 && (
            <ViewTableRow
              titleProps={{ style: { whiteSpace: 'nowrap' } }}
              valueProps={{ style: { width: '100%' } }}
              title={t('apps.links')}
              value={defaultValues.links.map((link: Link) => (
                <React.Fragment key={link.rel}>
                  <details style={{ width: '100%' }}>
                    <summary>{link.rel || link.href}</summary>
                    <table>
                      <tbody>
                        <tr>
                          <th>href:</th>
                          <td>{link.href}</td>
                        </tr>
                        <tr>
                          <th>prefetch:</th>
                          <td>{link.prefetch ?? 'false'}</td>
                        </tr>
                        <tr>
                          <th>prefetchURL:</th>
                          <td>{link.prefetchURL ?? '-/-'}</td>
                        </tr>
                        <tr>
                          <th>prefetchBaseURL:</th>
                          <td>{link.prefetchBaseURL ?? '-/-'}</td>
                        </tr>
                        <tr>
                          <th>prefetchProperty:</th>
                          <td>{link.prefetchProperty ?? '-/-'}</td>
                        </tr>
                        <tr>
                          <th>prefetchFallback:</th>
                          <td>{link.prefetchFallback ?? '-/-'}</td>
                        </tr>
                        <tr>
                          <th>prefetchMergeResultLinks:</th>
                          <td>{link.prefetchMergeResultLinks ?? 'false'}</td>
                        </tr>
                        <tr>
                          <th>onlyForApps:</th>
                          <td>
                            {link.onlyForApps?.length ? (
                              <ul>
                                {link.onlyForApps?.map((app: string) => <li key={app}>{app}</li>)}
                              </ul>
                            ) : '-/-'}
                          </td>
                        </tr>
                        <tr>
                          <th>appVersionLessThan:</th>
                          <td>{link.appVersionLessThan ?? '-/-'}</td>
                        </tr>
                        <tr>
                          <th>appVerappVersionGreaterOrEqual:</th>
                          <td>{link.appVersionGreaterOrEqual ?? '-/-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </details>
                  <hr />
                </React.Fragment>
              ))}
            />
          )}
        </ViewTable>
      </Container>

      {defaultValues.cortexDecoderKey && (
        <Container>
          <ViewTable headline={t('apps.cortexDecoderKey')}>
            <ViewTableRow
              title={t('form.labels.id')}
              value={(
                <LinkSpan onClick={() => navigate(`/cortex-decoder-keys/${defaultValues.cortexKeyID}`)}>
                  {defaultValues.cortexKeyID}
                </LinkSpan>
            )}
            />
            <ViewTableRow
              title={t('form.labels.name')}
              value={(
                <LinkSpan onClick={() => navigate(`/cortex-decoder-keys/${defaultValues.cortexKeyID}`)}>
                  {defaultValues.cortexDecoderKey?.name}
                </LinkSpan>
            )}
            />
            <ViewTableRow title={t('form.labels.validUntil')} value={parseDate(defaultValues.cortexDecoderKey?.validUntil)} />
            <ViewTableRow title={t('form.labels.updatedAt')} value={parseDatetime(defaultValues.cortexDecoderKey?.updatedAt)} />
          </ViewTable>
        </Container>
      )}
    </>
  );
}
