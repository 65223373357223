/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface TokenState {
  value: {
    raw: string;
    grants?: {
      [key: string]: any
    }
  }
}

const initialState:TokenState = {
  value: {
    raw: '',
  },
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    acquireToken: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { acquireToken } = tokenSlice.actions;

export default tokenSlice.reducer;
