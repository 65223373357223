import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import ViewOrganizationForm from './ViewOrganizationForm';
import useOrganizationsApi from '../../organizations/useOrganizationsApi';

export default function ViewOrganizationView() {
  const api = useOrganizationsApi();
  const { organizationID } = useParams();

  const fetchOrganization = useCallback(async () => {
    if (!organizationID) return {};
    const { data } = await api.getOrganization(organizationID);
    return data;
  }, [api, organizationID]);

  return (
    <>
      <DocumentTitle translationID="headlines.editOrganization" />
      <ResourceFormView
        actionName="editOrganization"
        Form={ViewOrganizationForm}
        fetch={fetchOrganization}
        submit={() => { }}
      />
    </>
  );
}
