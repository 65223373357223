import React, { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useOrganizationsApi, { OrganizationUpdate } from '../../organizations/useOrganizationsApi';
import EditOrganizationForm from './EditOrganizationForm';
import { ProjectConfig } from '../../project/useProjectApi';
import useProjectOverviewApi from '../ProjectOverview/useProjectOverviewApi';

export default function EditOrganizationView() {
  const api = useOrganizationsApi();
  const projectApi = useProjectOverviewApi();
  const navigate = useNavigate();
  const { organizationID } = useParams();

  const [etag, setEtag] = useState<string | undefined>('');
  const [availableProjects, setAvailableProjects] = useState<ProjectConfig[]>([]);

  const fetchOrganization = useCallback(async () => {
    if (!organizationID) return {};

    const projects: ProjectConfig[] = (await projectApi.getAllProjects(null))?.projects || [];
    const projectsForOrganization = projects
      .filter((project) => project.organizationID === organizationID);
    setAvailableProjects(projectsForOrganization);

    const { data, etag: newEtag } = await api.getOrganization(organizationID);
    setEtag(newEtag);
    return data;
  }, [api, organizationID, projectApi]);

  const updateOrganization = useCallback(async (data: FieldValues) => {
    if (!organizationID || !etag) return {};

    await api.updateOrganization({
      organizationId: organizationID,
      organizationData: data as OrganizationUpdate,
      etag,
    });
    setEtag(etag);
    return data;
  }, [api, etag, organizationID]);

  return (
    <>
      <DocumentTitle translationID="headlines.editOrganization" />
      <ResourceFormView
        actionName="editOrganization"
        Form={EditOrganizationForm}
        FormProps={{
          availableProjects,
        }}
        fetch={fetchOrganization}
        submit={updateOrganization}
        onSubmitted={() => navigate('/organizations')}
      />
    </>
  );
}
