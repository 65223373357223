import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import ViewAppForm from './ViewAppForm';
import useAppApi from '../../app/useAppApi';
import useCortexDecoderKeyApi from '../../license/useCortexDecoderKeyApi';

export default function ViewAppView() {
  const api = useAppApi();
  const cortexApi = useCortexDecoderKeyApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { appID } = useParams();

  const getKeyInfo = useCallback(async () => {
    if (!appID) return null;
    const { data } = await api.getApp(appID);
    if (!data) return null;
    const cortexKey = await cortexApi.getKey(data.cortexKeyID);
    return { ...data, cortexDecoderKey: cortexKey };
  }, [api, appID, cortexApi]);

  return (
    <>
      <DocumentTitle translationID="headlines.viewApp" />
      <ResourceFormView
        actionName="viewApp"
        headlineButtons={(
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => { navigate('edit'); }}
          >
            {t('apps.edit')}
          </Button>
        )}
        Form={ViewAppForm}
        fetch={getKeyInfo}
        submit={() => {}}
      />
    </>
  );
}
