import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { CancelButton, SubmitButton } from './button';
import { SubmitButtonFormState } from './button/SubmitButton';
import { CancelButtonFormState } from './button/CancelButton';
import FormActions from './FormActions';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
}));

export interface DefaultFormActionsProps {
  additionalButtons?: ReactNode;
  formState: SubmitButtonFormState & CancelButtonFormState;
  cancelNavigationTarget?: string;
}

export default function DefaultFormActions({
  additionalButtons,
  formState,
  cancelNavigationTarget,
}: DefaultFormActionsProps) {
  return (
    <Container>
      <FormActions allowReverse>
        <SubmitButton formState={formState} />
        <CancelButton formState={formState} href={cancelNavigationTarget} />
      </FormActions>

      {additionalButtons && (
        <FormActions>
          {additionalButtons}
        </FormActions>
      )}
    </Container>
  );
}
