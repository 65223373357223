import React, { useCallback, useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const style = () => ({
  clearIcon: {
    cursor: 'pointer',
  },
});

export interface SortableTableToolbarProps {
  numSelected: number,
  title: string,
  filterPlaceholder?: string,
  filterOverwrite?: string,
  onFilterChange: (newFilter: string) => void,
  verticalPadding?: number
}

export function SortableTableToolbar(props: SortableTableToolbarProps) {
  const {
    numSelected, title, filterPlaceholder, onFilterChange, verticalPadding = 1,
  } = props;
  const [filterString, setFilterString] = useState('');

  useEffect(() => {
    const { filterOverwrite } = props;
    if (!filterOverwrite) return;
    setFilterString(filterOverwrite);
    onFilterChange(filterOverwrite);
  }, [onFilterChange, props]);

  const handleFilterStringChange = useCallback((event: React.BaseSyntheticEvent) => {
    const { value } = event.target;
    setFilterString(value);
    onFilterChange(value);
  }, [onFilterChange]);

  const handleFilterStringClear = useCallback(() => {
    setFilterString('');
    onFilterChange('');
  }, [onFilterChange]);

  const classes = style();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: verticalPadding, sm: verticalPadding },
        ...(numSelected > 0 && {
          bgcolor:
          (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
          label={filterPlaceholder}
          variant="standard"
          size="small"
          value={filterString}
          onChange={handleFilterStringChange}
          data-testid="sortable-table-filter-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                { (filterString === '')
                  ? <Search />
                  : <ClearIcon onClick={handleFilterStringClear} style={classes.clearIcon} />}
              </InputAdornment>
            ),
          }}
        />
      )}
    </Toolbar>
  );
}
