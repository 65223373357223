import React from 'react';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

const DEFAULT_VALUES: any = {
};

interface CheckoutFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
}

export default function DiscountReasonsForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset category="discountReasons">
        <ArrayWrapper
          control={control}
          name="discountReasons"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.discountReason.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.discountReason.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <SelectField
                control={control}
                name={`discountReasons[${index}].method`}
                disabled={readonly}
                label={t('checkoutConfig.label.discount.method')}
              >
                <MenuItem value="priceOverwrite">priceOverwrite</MenuItem>
                <MenuItem value="basePriceOverwrite">basePriceOverwrite</MenuItem>
                <MenuItem value="priceEntry">priceEntry</MenuItem>
                <MenuItem value="manualCartDiscount">manualCartDiscount</MenuItem>
                <MenuItem value="manualLineItemDiscount">manualLineItemDiscount</MenuItem>
                <MenuItem value="promotion">promotion</MenuItem>
              </SelectField>
              <TextField
                control={control}
                name={`discountReasons[${index}].code`}
                disabled={readonly}
                label={t('checkoutConfig.label.discount.code')}
              />
              <TextField
                control={control}
                name={`discountReasons[${index}].message`}
                disabled={readonly}
                label={t('checkoutConfig.label.discount.message')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
