import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLayout from '../layout/PageLayout';
import ProjectSpecificRoutes from './ProjectSpecificRoutes';
import ProjectOverview from '../pages/ProjectOverview/ProjectOverview';
import RealmOverview from '../pages/RealmOverview/RealmOverview';
import RealmView from '../pages/RealmView/RealmView';
import NewProjectView from '../pages/ProjectView/NewProjectView';
import LoginConfirmationDialog from './LoginConfirmationDialog';
import NewRealmView from '../pages/RealmView/NewRealmView';
import CortexDecoderKeysOverview from '../pages/CortexDecoderKeysOverview/CortexDecoderKeysOverview';
import ViewCortexDecoderKeyView from '../pages/CortexDecoderKeyView/ViewCortexDecoderKeyView';
import EditCortexDecoderKeyView from '../pages/CortexDecoderKeyView/EditCortexDecoderKeyView';
import CreateCortexDecoderKeyView from '../pages/CortexDecoderKeyView/CreateCortexDecoderKeyView';
import AppsOverview from '../pages/AppsOverview/AppsOverview';
import ViewAppView from '../pages/AppView/ViewAppView';
import EditAppView from '../pages/AppView/EditAppView';
import CreateAppView from '../pages/AppView/CreateAppView';
import OrganizationsOverview from '../pages/OrganizationsOverview/OrganizationsOverview';
import CreateOrganizationView from '../pages/OrganizationView/CreateOrganizationView';
import ViewOrganizationView from '../pages/OrganizationView/ViewOrganizationView';
import EditOrganizationView from '../pages/OrganizationView/EditOrganizationView';

function AppWithLayout({ children }) {
  const rawToken = useSelector((state) => state.token.value.raw);
  const authorized = useSelector((state) => state.authorization.authorized);

  const loginDialog = (!rawToken || !authorized) && (
    <LoginConfirmationDialog />
  );

  return (
    <PageLayout>
      {children}
      {loginDialog}
    </PageLayout>
  );
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/" element={<AppWithLayout><ProjectOverview /></AppWithLayout>} />
        <Route path="/projects/new" element={<AppWithLayout><NewProjectView /></AppWithLayout>} />
        <Route path="/projects/new/:projectId" element={<AppWithLayout><NewProjectView /></AppWithLayout>} />
        <Route path="/projects/:projectId/*" element={<AppWithLayout><ProjectSpecificRoutes /></AppWithLayout>} />
        <Route path="/realms" element={<AppWithLayout><RealmOverview /></AppWithLayout>} />
        <Route path="/realms/new" element={<AppWithLayout><NewRealmView /></AppWithLayout>} />
        <Route path="/realms/new/:realmID" element={<AppWithLayout><NewRealmView /></AppWithLayout>} />
        <Route path="/realms/:realmID" element={<AppWithLayout><RealmView /></AppWithLayout>} />
        <Route path="/cortex-decoder-keys" element={<AppWithLayout><CortexDecoderKeysOverview /></AppWithLayout>} />
        <Route path="/cortex-decoder-keys/new" element={<AppWithLayout><CreateCortexDecoderKeyView /></AppWithLayout>} />
        <Route path="/cortex-decoder-keys/:keyID" element={<AppWithLayout><ViewCortexDecoderKeyView /></AppWithLayout>} />
        <Route path="/cortex-decoder-keys/:keyID/edit" element={<AppWithLayout><EditCortexDecoderKeyView /></AppWithLayout>} />
        <Route path="/apps" element={<AppWithLayout><AppsOverview /></AppWithLayout>} />
        <Route path="/apps/new" element={<AppWithLayout><CreateAppView /></AppWithLayout>} />
        <Route path="/apps/:appID" element={<AppWithLayout><ViewAppView /></AppWithLayout>} />
        <Route path="/apps/:appID/edit" element={<AppWithLayout><EditAppView /></AppWithLayout>} />
        <Route path="/organizations" element={<AppWithLayout><OrganizationsOverview /></AppWithLayout>} />
        <Route path="/organizations/new" element={<AppWithLayout><CreateOrganizationView /></AppWithLayout>} />
        <Route path="/organizations/:organizationID" element={<AppWithLayout><ViewOrganizationView /></AppWithLayout>} />
        <Route path="/organizations/:organizationID/edit" element={<AppWithLayout><EditOrganizationView /></AppWithLayout>} />
      </Routes>
    </BrowserRouter>
  );
}
