import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectOptionsField } from '../../apiForm/form/input';
import { CheckoutDeviceType } from '../../project/useCheckoutDevicesApi';

const DEFAULT_VALUES: any = {
};

interface CheckoutDevicesFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
}

export default function CheckoutDevicesForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: CheckoutDevicesFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="deviceTypes">
        <SelectOptionsField
          control={control}
          name="availableDeviceTypes"
          options={Object.values(CheckoutDeviceType)}
          multiple
          helperText={t('helperText.availableDeviceTypes')}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
