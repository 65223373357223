import React from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomFormProps } from '../../apiForm/form';
import ViewTable from '../../DataDisplay/ViewTable/ViewTable';
import ViewTableRow from '../../DataDisplay/ViewTable/ViewTableRow';

const NoneBreakingSpan = styled('span')(() => ({
  whiteSpace: 'nowrap',
}));

const LinkSpan = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const DEFAULT_VALUES: any = {};

interface ViewOrganizationFormProps extends CustomFormProps { }

export default function ViewOrganizationForm({
  defaultValues = DEFAULT_VALUES,
}: ViewOrganizationFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box marginBottom={4}>
        <ViewTable headline={t('form.fieldsets.general')}>
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.id')}</NoneBreakingSpan>}
            value={defaultValues.id}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.name')}</NoneBreakingSpan>}
            value={defaultValues.name}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.description')}</NoneBreakingSpan>}
            value={defaultValues.description}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.taxNumber')}</NoneBreakingSpan>}
            value={defaultValues.taxNumber}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.taxID')}</NoneBreakingSpan>}
            value={defaultValues.taxID}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.currency')}</NoneBreakingSpan>}
            value={defaultValues.currency}
          />
        </ViewTable>
      </Box>

      <Box marginBottom={4}>
        <ViewTable headline={t('form.fieldsets.general')}>
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.currency')}</NoneBreakingSpan>}
            value={defaultValues.currency}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.street')}</NoneBreakingSpan>}
            value={defaultValues.street}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.city')}</NoneBreakingSpan>}
            value={defaultValues.city}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.zip')}</NoneBreakingSpan>}
            value={defaultValues.zip}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.country')}</NoneBreakingSpan>}
            value={defaultValues.country}
          />
          <ViewTableRow
            title={<NoneBreakingSpan>{t('form.labels.website')}</NoneBreakingSpan>}
            value={defaultValues.website}
          />
        </ViewTable>
      </Box>

      <Box marginBottom={4}>
        <ViewTable headline={t('form.fieldsets.templateProjects')}>
          {defaultValues.templateProjects?.length > 0 ? (
            <ViewTableRow
              title={t('form.labels.project')}
              value={(
                <ul>
                  {defaultValues.templateProjects.map((project: string) => (
                    <li key={`${project}`}>
                      <LinkSpan onClick={() => navigate(`/projects/${project}`)}>
                        {project}
                      </LinkSpan>
                    </li>
                  ))}
                </ul>
              )}
            />
          ) : (
            <ViewTableRow
              title={t('form.fieldsets.projects')}
              value={t('organizations.noTemplateProjects')}
            />
          )}
        </ViewTable>
      </Box>
    </>
  );
}
