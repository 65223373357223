import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useTranslation } from 'react-i18next';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/material/styles/createTheme';

const styles = (theme: Theme) => ({
  switch: {
    maxWidth: '25%',
    minWidth: theme.spacing(16),
    backgroundColor: '#fff',
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(2.25),

    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: '34px',
    },
  },
});

const setDateFormat = ((code: any) => {
  switch (code) {
    case 'de':
      return moment.updateLocale('de', {
        calendar: {
          sameElse: 'DD.MM.YYYY HH:mm',
        },
      });
    default:
      return moment.updateLocale('en', {
        calendar: {
          sameElse: 'YYYY/MM/DD hh:mm A',
        },
      });
  }
});

const LANGUAGE_TITLES:any = {
  en: 'English',
  de: 'Deutsch',
};

export default function LanguageSwitch() {
  const theme = useTheme();
  const classes = styles(theme);

  const [, i18n] = useTranslation();

  useEffect(() => {
    // TODO: This is a strange side-effect of this component that retained after
    // porting this component from class-based to function-based. We should
    // probably do this somewhere else (e.g. index.js)
    setDateFormat(i18n.resolvedLanguage);
  }, [i18n]);

  const languages = useMemo(() => (
    (i18n.options.supportedLngs || [])
      .map((locale) => ({ title: LANGUAGE_TITLES[locale], locale }))
      .filter(({ title }) => Boolean(title))
  ), [i18n]);

  function handleChange(event: SelectChangeEvent) {
    const locale = event.target.value;
    i18n.changeLanguage(locale);
    setDateFormat(locale);
  }

  return (
    <Select
      style={classes.switch}
      value={i18n.resolvedLanguage}
      size="small"
      onChange={(event) => { handleChange(event); }}
    >
      {languages.map(({ title, locale }) => (
        <MenuItem key={locale} value={locale}>{title}</MenuItem>
      ))}
    </Select>
  );
}
