import React, { useCallback, useRef } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useOrganizationsApi from '../../organizations/useOrganizationsApi';
import EditOrganizationForm from './EditOrganizationForm';

export default function CreateOrganizationView() {
  const api = useOrganizationsApi();
  const navigate = useNavigate();

  const organizationId = useRef<string | undefined>(undefined);

  const createOrganization = useCallback(async (data: FieldValues) => {
    const { id } = await api.createOrganization(data as any);
    organizationId.current = id;
  }, [api]);

  const navigateToViewPage = useCallback(() => {
    if (!organizationId.current) {
      navigate('/organizations');
    }
    navigate(`/organizations/${organizationId.current}/edit`);
  }, [navigate]);

  return (
    <>
      <DocumentTitle translationID="headlines.createOrganization" />
      <ResourceFormView
        actionName="createOrganization"
        Form={EditOrganizationForm}
        fetch={() => ({})}
        submit={createOrganization}
        onSubmitted={() => navigateToViewPage()}
      />
    </>
  );
}
