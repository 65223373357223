import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { matchSorter } from 'match-sorter';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import SearchableSelectField from '../../apiForm/form/input/SearchableSelectField';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import { ProjectConfig } from '../../project/useProjectApi';

const DEFAULT_VALUES: any = {};

const searchableProjectSelectSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};

interface EditOrganizationsFormProps extends CustomFormProps {
  availableProjects: ProjectConfig[];
}

export default function EditOrganizationForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  availableProjects,
}: EditOrganizationsFormProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="general">
        {defaultValues.id ? (
          <TextField
            control={control}
            name="id"
            disabled
          />
        ) : (
          <TextField
            control={control}
            name="idPrefix"
            label={t('organizations.label.idPrefix')}
            helperText={t('organizations.helperText.idPrefix')}
            required
            rules={{
              required: true,
              pattern: /^[a-z0-9-]+$/,
              minLength: 3,
              maxLength: 32,
            }}
          />
        )}
        <TextField
          control={control}
          name="name"
          required
          rules={{ required: true }}
        />
        <TextField
          control={control}
          name="description"
        />
        <TextField
          control={control}
          name="taxNumber"
        />
        <TextField
          control={control}
          name="taxID"
        />
        <TextField
          control={control}
          name="currency"
        />
      </Fieldset>

      <Fieldset category="contact">
        <TextField
          control={control}
          name="street"
        />
        <TextField
          control={control}
          name="city"
        />
        <TextField
          control={control}
          name="zip"
        />
        <TextField
          control={control}
          name="country"
        />
        <TextField
          control={control}
          name="website"
        />
      </Fieldset>

      <Fieldset category="templateProjects">
        <ArrayWrapper
          control={control}
          name="templateProjects"
          defaultValue=""
          inlineDeleteButton
          render={(index: number) => (
            <Stack spacing={1} sx={{ width: '100%' }}>
              <SearchableSelectField
                control={control}
                name={`templateProjects[${index}]`}
                sorterOptions={searchableProjectSelectSorterOptions}
                options={availableProjects}
                labelFrom="name"
                valueFrom="id"
                label={t('form.labels.project')}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/organizations"
      />
    </Form>
  );
}
