import { useResourceTranslator } from '../apiForm/resource';

type DocumentTitleProps = { translationID: string, data?: any };

export default function DocumentTitle({ translationID, data }: DocumentTitleProps) {
  const t = useResourceTranslator();
  const d = data || {};
  document.title = `${t(translationID, d)} | snabble`;
  return null;
}
