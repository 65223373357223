import React, { useCallback } from 'react';
import { FieldValue } from 'react-hook-form';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import useCheckoutDevicesApi from '../../project/useCheckoutDevicesApi';
import CheckoutDevicesForm from './CheckoutDevicesForm';

export default function CheckoutDevicesView() {
  const api = useCheckoutDevicesApi();

  const fetch = useCallback(async () => {
    const availableDeviceTypes = await api.getAvailableDeviceTypes(null);
    const response = {
      availableDeviceTypes,
    };
    return response;
  }, [api]);

  const onSubmit = useCallback(async (data: FieldValue<any>) => {
    await api.updateAvailableDeviceTypes(data.availableDeviceTypes);
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="sidebarView.headline" />
      <div>
        <ResourceFormView
          actionName="checkoutDevices"
          Form={CheckoutDevicesForm}
          fetch={fetch}
          submit={onSubmit}
        />
      </div>
    </>
  );
}
