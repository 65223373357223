import React, { useMemo } from 'react';
import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';
import SearchableSelect from './SearchableSelect';

export const searchableProjectSelectSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};

interface ProjectFilterProps<T> extends Record<string, any> {
  projects: T[];
  onChange: (project: T | T[] | null) => void;
  showLabel?: boolean;
  defaultProjectId?: string;
}

export default function SearchableProjectSelect<T>({
  projects,
  onChange,
  showLabel = false,
  defaultProjectId,
  ...props
}: ProjectFilterProps<T>) {
  const { t } = useTranslation();

  const defaultProject = useMemo(() => {
    if (!defaultProjectId) return undefined;
    return projects?.find((project: any) => (project.id === defaultProjectId));
  }, [defaultProjectId, projects]);

  const label = showLabel ? t('filter.byProject') : undefined;

  return (
    <SearchableSelect
      onChange={(_, selectedProject) => { onChange(selectedProject as any); }}
      options={projects as any}
      sorterOptions={searchableProjectSelectSorterOptions}
      label={label}
      defaultValue={defaultProject as any}
      labelFrom="name"
      {...props}
    />
  );
}
