import React from 'react';
import { NavLink } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/material/styles/createTheme';
import SidebarMenuItemContent from './SidebarMenuItemContent';

type SidebarMenuItemProps = {
  linkText?: string,
  translationId?: string,
  icon?: React.ReactNode,
  linkTarget: string,
  disabled?: boolean,
};

const styles = (theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  linkActive: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
});

export default function SidebarMenuItem({
  linkText,
  translationId,
  icon,
  linkTarget,
  disabled,
}: SidebarMenuItemProps) {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <NavLink
      to={linkTarget}
      style={({ isActive }) => (isActive ? classes.linkActive : classes.link)}
    >
      <SidebarMenuItemContent
        linkText={linkText}
        translationId={translationId}
        icon={icon}
        disabled={disabled}
      />
    </NavLink>
  );
}
