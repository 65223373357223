import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import SearchableProjectSelect from './SearchableProjectSelect';
import useProjectOverviewApi from '../pages/ProjectOverview/useProjectOverviewApi';

const StyledSearchableProjectSelect = styled(SearchableProjectSelect)({
  '& .MuiAutocomplete-inputRoot': {
    color: 'white',
    paddingLeft: '32px',
    paddingBlock: '4px',
  },
  '& ::selection': {
    backgroundColor: 'white',
    color: '#07b',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      color: 'white',
    },
  },
  '& .MuiAutocomplete-endAdornment': {
    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
  },
});

export default function SnabbleMenuProjectSelector() {
  const { getAllProjects } = useProjectOverviewApi();

  const { projectId } = useParams();
  const [projects, setProjects] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      setProjects((await getAllProjects(null))?.projects);
    };
    fetchData();
  }, [getAllProjects]);

  const handleProjectChange = useCallback((targetProject: any) => {
    const url = `${document.location.origin}/projects/${targetProject.id}`;
    document.location.assign(url);
  }, []);

  if (!projectId || !projects) {
    return (
      <Typography
        variant="button"
        color="white"
        sx={{
          marginLeft: '40px',
        }}
      >
        Admin Portal
      </Typography>
    );
  }

  return (
    <StyledSearchableProjectSelect
      onChange={handleProjectChange}
      showLabel={false}
      defaultProjectId={projectId}
      sx={{
        color: 'white',
      }}
      fullWidth
      disableClearable
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          multiline
        />
      )}
      projects={projects || []}
    />
  );
}
