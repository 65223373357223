import { defineApi, useApiClient } from '../apiForm/api';

export interface Organization {
  id: string;
  name: string;
  description: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  website: string;
  taxNumber: string;
  taxID: string;
  currency: string;
  templateProjects: string[];
}

export type OrganizationUpdate = Omit<Organization, 'id'>;

interface NewOrganization extends OrganizationUpdate {
  idPrefix: string;
}

interface UpdateOrganizationProps {
  organizationId: string;
  organizationData: OrganizationUpdate;
  etag: string;
}

function sanitizeData(data: OrganizationUpdate) {
  let cleanedTemplateProjects = data.templateProjects.filter((p) => Boolean(p));
  cleanedTemplateProjects = [...new Set(cleanedTemplateProjects)];
  return {
    ...data,
    templateProjects: cleanedTemplateProjects,
  };
}

const useApi = defineApi({
  getOrganizations: async (client) => {
    const { data } = await client.get<Organization[]>('');
    return data;
  },
  getOrganization: async (client, organizationId: string) => {
    const { data, headers } = await client.get<Organization>(`/${organizationId}`);
    return { data, etag: headers.etag };
  },
  createOrganization: async (client, organizationData: NewOrganization) => {
    const { data } = await client({
      url: '',
      method: 'POST',
      data: sanitizeData(organizationData),
    });
    return data;
  },
  updateOrganization: async (client, {
    organizationId,
    organizationData,
    etag,
  }: UpdateOrganizationProps) => {
    await client({
      url: `/${organizationId}`,
      method: 'PUT',
      data: sanitizeData(organizationData),
      headers: {
        'If-Match': etag,
      },
    });
  },
});

export default function useOrganizationsApi() {
  const client = useApiClient({ basePath: '/organizations' });
  return useApi(client);
}
