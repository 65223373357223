import {
  defineApi,
  useApiClient,
} from '../../apiForm/api';
import { ProjectConfig } from '../../project/useProjectApi';

export interface ProjectOverviewData {
  'links': any,
  'projects': ProjectConfig[],
}

const useApi = defineApi({
  getAllProjects: (client) => client.get('/metadata/project').then((resp) => resp.data),
});

export default function useProjectOverviewApi() {
  const client = useApiClient({ basePath: '' });
  return useApi(client);
}
