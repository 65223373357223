import { FieldValues } from 'react-hook-form';
import { defineApi, useApiClient } from '../apiForm/api';
import removeUndefinedObjectEntries, { repairObject } from '../utils/apiObjectUtils';

export enum ProjectRole {
  PROJECT_ADMIN = 'projectAdmin',
  PROJECT_READER = 'projectReader',
  PROJECT_GUEST = 'projectGuest',
  CASHIER = 'cashier',
  SUPERVISOR = 'supervisor',
  REALM_ADMIN = 'realmAdmin',
  ORGANIZATION_ADMIN = 'organizationAdmin',
}

export enum PasswordChoicePolicy {
  RESET_VIA_EMAIL = 'resetViaEmail',
  SET_BY_CREATOR = 'setByCreator',
  ANY = 'any',
}

export enum SubValidationPolicyTypes {
  EMAIL = 'email',
  NUMERIC = 'numeric',
  ALPHANUMERIC = 'alphaNumeric',
}

export enum PasswordValidationPolicyTypes {
  ANY = 'any',
  NUMERIC = 'numeric',
  ALPHANUMERIC = 'alphaNumeric',
}

export interface ValidationPolicy<T> {
  type?: T;
  minLength?: number;
  maxLength?: number;
}

export interface Realm {
  id: string;
  name: string;
  subValidationPolicy: ValidationPolicy<SubValidationPolicyTypes>;
  passwordValidationPolicy: ValidationPolicy<PasswordValidationPolicyTypes>;
  passwordChoicePolicy: PasswordChoicePolicy,
  allowedRoles?: ProjectRole[];
  allowedProjects?: string[];
  links: {
    self: {
      href: string;
    }
  }
}

export type NewRealm = Omit<Realm, 'links'>;

export type RealmUpdate = Omit<Realm, 'id' | 'links'>;

interface GetRealmsData {
  realms: Realm[];
  links: Record<string, string>;
}

interface UpdateRealmProps {
  realmID: string;
  realmData: RealmUpdate;
}

const sanitizeData = (data: FieldValues) => {
  const subValidationPolicy = { ...data.subValidationPolicy };
  subValidationPolicy.minLength = parseInt(subValidationPolicy.minLength, 10) || undefined;
  subValidationPolicy.maxLength = parseInt(subValidationPolicy.maxLength, 10) || undefined;

  const passwordValidationPolicy = { ...data.passwordValidationPolicy };
  // eslint-disable-next-line max-len
  passwordValidationPolicy.minLength = parseInt(passwordValidationPolicy.minLength, 10) || undefined;
  // eslint-disable-next-line max-len
  passwordValidationPolicy.maxLength = parseInt(passwordValidationPolicy.maxLength, 10) || undefined;

  let cleanedData: FieldValues = {
    ...data,
    subValidationPolicy,
    passwordValidationPolicy,
  };
  delete cleanedData.links;
  cleanedData = repairObject(cleanedData);
  cleanedData = removeUndefinedObjectEntries(cleanedData);

  return cleanedData;
};

const useApi = defineApi({
  getRealms: async (client) => {
    const { data } = await client.get<GetRealmsData>('');
    return data;
  },
  getRealm: async (client, realmID: string) => {
    const { data } = await client.get<Realm>(`/${realmID}`);
    return data;
  },
  createRealm: async (client, realmData: NewRealm) => {
    const newRealm = await client<Realm>({
      url: '',
      method: 'POST',
      data: sanitizeData(realmData),
    });
    return newRealm;
  },
  updateRealm: async (client, { realmID, realmData }: UpdateRealmProps) => {
    await client({
      url: `/${realmID}`,
      method: 'PUT',
      data: sanitizeData(realmData),
    });
  },
  deleteRealm: async (client, realmID: string) => {
    await client.delete(`/${realmID}`);
  },
});

export default function useRealmApi() {
  const client = useApiClient({ basePath: '/realms' });
  return useApi(client);
}
