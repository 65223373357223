import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useProjectApi from '../../project/useProjectApi';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import ProjectForm from './ProjectForm';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useOrganizationsApi, { Organization } from '../../organizations/useOrganizationsApi';

export default function ProjectView() {
  const api = useProjectApi();
  const organizationApi = useOrganizationsApi();

  const routerParams = useParams();
  const projectId = routerParams.projectId || '';
  const [availableOrganizations, setAvailableOrganizations] = useState<Organization[]>([]);

  const [etag, setEtag] = useState('');

  const getProjectsInfo = useCallback(async () => {
    const [responseData, responseEtag] = await api.getProjectInfo({ projectId });
    setEtag(responseEtag);

    setAvailableOrganizations(await organizationApi.getOrganizations(null) || []);

    return responseData;
  }, [api, organizationApi, projectId]);

  return (
    <>
      <DocumentTitle translationID="headlines.projectView" />
      <ResourceFormView
        actionName="projectView"
        Form={ProjectForm}
        FormProps={{
          availableOrganizations,
        }}
        fetch={getProjectsInfo}
        submit={(data) => api.updateProjectInfo({ projectId, data, etag })}
        onSubmitted={getProjectsInfo}
      />
    </>
  );
}
