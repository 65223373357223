import { FieldValues } from 'react-hook-form';
import CheckoutDeviceAppearanceIcon from '@mui/icons-material/Palette';
import AppCustomizationIcon from '@mui/icons-material/AppShortcut';
import {
  defineApi,
  useApiClient,
} from '../apiForm/api';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
// @ts-ignore
import { ReactComponent as DashboardIcon } from '../icons/team_dashboard.svg';
// @ts-ignore
import { ReactComponent as StatisticsIcon } from '../icons/monitoring.svg';
// @ts-ignore
import { ReactComponent as OrdersIcon } from '../icons/folder_open.svg';
// @ts-ignore
import { ReactComponent as ProductsIcon } from '../icons/shelves.svg';
// @ts-ignore
import { ReactComponent as ShopsIcon } from '../icons/storefront.svg';
// @ts-ignore
import { ReactComponent as ShopAccessIcon } from '../icons/passkey.svg';
// @ts-ignore
import { ReactComponent as DevicesIcon } from '../icons/devices.svg';
// @ts-ignore
import { ReactComponent as ReleasesIcon } from '../icons/deployed_code.svg';
// @ts-ignore
import { ReactComponent as CheckoutSupervisorIcon } from '../icons/security.svg';
// @ts-ignore
import { ReactComponent as UserManagemenIcon } from '../icons/manage_accounts.svg';
// @ts-ignore
import { ReactComponent as ProjectConigIcon } from '../icons/manufacturing.svg';

export enum SidebarProjectRole {
  PROJECT_ADMIN = 'projectAdmin',
  PROJECT_READER = 'projectReader',
  PROJECT_GUEST = 'projectGuest',
}

// NOTE update this enum if new entries can be set
export enum SidebarItem {
  DASHBOARD = 'dashboard',
  STATISTICS = 'statistics',
  ORDERS = 'orders',
  ORDERS_OVERVIEW = 'ordersOverview',
  ORDERS_EXPORT = 'ordersExport',
  ORDERS_EXPORT_LINE_ITEMS = 'ordersExportLineItems',
  CLOSINGS = 'closings',
  CLOSINGS_SCHEDULES = 'closingsSchedules',
  PRODUCTS = 'products',
  PRODUCTS_OVERVIEW = 'productsOverview',
  PRICING_CATEGORIES = 'pricingCategories',
  TAX_RULES = 'taxRules',
  SHOPS = 'shops',
  SHOP_ACCESS = 'shopAccess',
  CHECKOUT_DEVICES = 'checkoutDevices',
  RELEASES = 'releases',
  CHECKOUT_SUPERVISOR = 'checkoutSupervisor',
  USER_MANAGEMENT = 'userManagement',
  PROJECT_CONFIG = 'projectConfig',
  CHECKOUT_DEVICE_APPEARANCE = 'checkoutDeviceAppearance',
  APP_CUSTOMIZATION = 'appCustomization',
}

interface NavigationData {
  projectID: string;
  relationsForRoles: Record<SidebarProjectRole, SidebarItem[]>;
  links: any;
}

interface NavigationFormData {
  projectID: string;
  relationsForRoles: Record<SidebarProjectRole, Record<SidebarItem, boolean>>;
  links: any;
}

// NOTE update this to set a different default value
function createDefaultData(projectID: string): NavigationData {
  return {
    projectID,
    relationsForRoles: {
      [SidebarProjectRole.PROJECT_ADMIN]: [
        SidebarItem.DASHBOARD,
      ],
      [SidebarProjectRole.PROJECT_READER]: [
        SidebarItem.DASHBOARD,
      ],
      [SidebarProjectRole.PROJECT_GUEST]: [
        SidebarItem.DASHBOARD,
      ],
    },
    links: {},
  };
}

interface SidebarMaskEntry {
  id: SidebarItem;
  nameOverride?: SidebarItem;
  nonInteractive?: boolean;
  disabledForRoles?: SidebarProjectRole[];
  icon?: any;
  children?: SidebarMaskEntry[];
}

// NOTE update this to set a new mask and change how the items are shown in the editor
export const SIDEBAR_MASK: SidebarMaskEntry[] = [
  {
    id: SidebarItem.DASHBOARD,
    icon: DashboardIcon,
    disabledForRoles: [
      SidebarProjectRole.PROJECT_ADMIN,
      SidebarProjectRole.PROJECT_READER,
      SidebarProjectRole.PROJECT_GUEST,
    ],
  },
  {
    id: SidebarItem.STATISTICS,
    icon: StatisticsIcon,
  },
  {
    id: SidebarItem.ORDERS,
    icon: OrdersIcon,
    nonInteractive: true,
    children: [
      {
        id: SidebarItem.ORDERS_OVERVIEW,
        nameOverride: SidebarItem.ORDERS,
      },
      {
        id: SidebarItem.ORDERS_EXPORT,
        nameOverride: SidebarItem.ORDERS,
      },
      {
        id: SidebarItem.ORDERS_EXPORT_LINE_ITEMS,
        nameOverride: SidebarItem.ORDERS,
      },
      {
        id: SidebarItem.CLOSINGS,
      },
      {
        id: SidebarItem.CLOSINGS_SCHEDULES,
      },
    ],
  },
  {
    id: SidebarItem.PRODUCTS,
    icon: ProductsIcon,
    nonInteractive: true,
    children: [
      {
        id: SidebarItem.PRODUCTS_OVERVIEW,
        nameOverride: SidebarItem.PRODUCTS,
      },
      {
        id: SidebarItem.PRICING_CATEGORIES,
      },
      {
        id: SidebarItem.TAX_RULES,
      },
    ],
  },
  {
    id: SidebarItem.SHOPS,
    icon: ShopsIcon,
    disabledForRoles: [SidebarProjectRole.PROJECT_GUEST],
  },
  {
    id: SidebarItem.SHOP_ACCESS,
    icon: ShopAccessIcon,
  },
  {
    id: SidebarItem.CHECKOUT_DEVICES,
    icon: DevicesIcon,
  },
  {
    id: SidebarItem.CHECKOUT_DEVICE_APPEARANCE,
    icon: CheckoutDeviceAppearanceIcon,
  },
  {
    id: SidebarItem.APP_CUSTOMIZATION,
    icon: AppCustomizationIcon,
    disabledForRoles: [SidebarProjectRole.PROJECT_GUEST],
  },
  {
    id: SidebarItem.RELEASES,
    icon: ReleasesIcon,
  },
  {
    id: SidebarItem.CHECKOUT_SUPERVISOR,
    icon: CheckoutSupervisorIcon,
  },
  {
    id: SidebarItem.USER_MANAGEMENT,
    icon: UserManagemenIcon,
    disabledForRoles: [SidebarProjectRole.PROJECT_READER, SidebarProjectRole.PROJECT_GUEST],
  },
  {
    id: SidebarItem.PROJECT_CONFIG,
    icon: ProjectConigIcon,
    disabledForRoles: [SidebarProjectRole.PROJECT_GUEST],
  },
];

interface UpdateSidebarLayoutProps {
  layout: FieldValues;
  etag: string;
  projectId: string | undefined;
}

function convertApiDataToFormData(entries: NavigationData): NavigationFormData {
  const relationsAsArrays = entries.relationsForRoles;
  const relationsAsObjects: Partial<Record<SidebarProjectRole, Record<SidebarItem, boolean>>> = {};
  Object.keys(relationsAsArrays).forEach((role) => {
    const itemsVisibility: Partial<Record<SidebarItem, boolean>> = {};
    Object.values(SidebarItem).forEach((item) => {
      itemsVisibility[item] = relationsAsArrays[role as SidebarProjectRole].includes(item);
    });
    // eslint-disable-next-line max-len
    relationsAsObjects[role as SidebarProjectRole] = itemsVisibility as Record<SidebarItem, boolean>;
  });

  const entriesCopy = structuredClone(entries) as any;
  entriesCopy.relationsForRoles = relationsAsObjects;
  return entriesCopy as NavigationFormData;
}

function convertFormDataToApiData(entries: NavigationFormData): NavigationData {
  const relationsAsObjects: any = entries.relationsForRoles;
  const relationsAsArrays: Partial<Record<SidebarProjectRole, SidebarItem[]>> = {};
  Object.keys(relationsAsObjects).forEach((role) => {
    const itemsVisibility: SidebarItem[] = [];
    Object.keys(relationsAsObjects[role]).forEach((item) => {
      if (relationsAsObjects[role][item]) itemsVisibility.push(item as SidebarItem);
    });
    relationsAsArrays[role as SidebarProjectRole] = itemsVisibility;
  });

  const entriesCopy = structuredClone(entries) as any;
  entriesCopy.relationsForRoles = relationsAsArrays;
  return entriesCopy as NavigationData;
}

// eslint-disable-next-line max-len
export function getSidebarDefaults(projectId: string | undefined): { data: NavigationFormData, etag: string } {
  if (!projectId) throw new Error('Missing project id');
  return { data: convertApiDataToFormData(createDefaultData(projectId)), etag: '"*"' };
}

const useApi = defineApi({
  getSidebarLayout: async (client, { projectId }: { projectId: string | undefined }) => {
    if (!projectId) throw new Error('Missing project id');
    const { data, headers, status } = await client({
      url: `/projects/${projectId}/navigation`,
      method: 'GET',
      validateStatus: (statusCode) => isSuccessfulOrCustomResponse(statusCode, [404]),
    });
    if (status === 404) return null;
    return { data: convertApiDataToFormData(data), etag: headers.etag };
  },
  updateSidebarLayout: async (client, { layout, etag, projectId }: UpdateSidebarLayoutProps) => {
    if (!projectId) throw new Error('Missing project id');
    try {
      await client({
        url: `/projects/${projectId}/navigation`,
        method: 'PUT',
        data: convertFormDataToApiData(layout as NavigationFormData),
        headers: {
          'If-Match': etag,
        },
      });
    } catch (e) {
      throw new Error('Failed to update sidebar layout');
    }
  },
});

export default function useSidebarApi() {
  const client = useApiClient({ basePath: '' });
  return useApi(client);
}
