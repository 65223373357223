import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';
import { Panel } from '../panel';
import FieldStack from './FieldStack';
import { useResourceTranslator } from '../resource';
import { FieldsetProps } from './Fieldset';

const AccordionSummaryFoo = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  paddingBlock: '8px',
  marginBottom: '16px',
}));

export interface ExpandableFieldsetProps extends FieldsetProps {
  sublegend?: string;
  defaultExpanded?: boolean;
}

export default function ExpandableFieldset({
  category,
  children,
  legend,
  sublegend,
  defaultExpanded = true,
}: ExpandableFieldsetProps) {
  const t = useResourceTranslator();

  return (
    <Panel>
      <Accordion
        TransitionProps={{ timeout: 200 }}
        defaultExpanded={defaultExpanded}
      >
        {(category || legend) && (
        <AccordionSummaryFoo>
          <Stack>
            <Typography variant="button">
              {legend ?? t(`form.fieldsets.${category}`, { defaultValue: legend })}
            </Typography>
            {sublegend && (
              <Typography variant="caption">
                {sublegend}
              </Typography>
            )}
          </Stack>
        </AccordionSummaryFoo>
        )}

        <AccordionDetails>
          <FieldStack>
            {children}
          </FieldStack>
        </AccordionDetails>
      </Accordion>

    </Panel>
  );
}
