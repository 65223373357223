import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

type SidebarProps = {
  mobileOpen: boolean,
  onDrawerToggle: () => void,
};

const styles = () => ({
  header: {
    padding: 0,
  },
});

const drawerWidth = 240;

export default function Sidebar({ mobileOpen, onDrawerToggle }: SidebarProps) {
  const classes = styles();

  const drawerContent = (
    <>
      <Toolbar style={classes.header} sx={{ height: '76px' }}>
        <SidebarHeader />
      </Toolbar>
      <Divider />
      <SidebarMenu />
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
}
