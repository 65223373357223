import { defineApi, useApiClient } from '../apiForm/api';
import { CortexKey } from '../license/useCortexDecoderKeyApi';

export interface AppVersion {
  platform: string;
  version: string;
  lessThan: boolean;
}

export interface Link {
  rel?: string;
  href?: string;
  prefetch?: boolean;
  prefetchURL?: string;
  prefetchBaseURL?: string;
  prefetchProperty?: string;
  prefetchFallback?: string;
  prefetchMergeResultLinks?: boolean;
  onlyForApps?: string[];
  appVersionLessThan?: string;
  appVersionGreaterOrEqual?: string;
}

export interface AppConfig {
  id: string;
  projects: string[];
  killedVersions: AppVersion[];
  cortexKeyID: number;
  links: Link[];
}

export interface AppConfigWithCortexDecoderKey extends AppConfig {
  cortexDecoderKey: CortexKey;
}

export interface AppList {
  apps: AppConfig[];
}

export interface NewAppConfig extends Omit<AppConfig, 'id'> {
  idPrefix: string;
}

export interface UpdateAppProps {
  appID: AppConfig['id'];
  etag: string;
  appData: Partial<NewAppConfig>;
}

const useApi = defineApi({
  getApps: async (client) => {
    const { data } = await client.get<AppList>('');
    return data.apps;
  },
  getApp: async (client, appID: AppConfig['id']) => {
    const { data, headers } = await client.get<AppConfig>(`/${appID}`);
    return { data, etag: headers.etag };
  },
  createApp: async (client, newAppData: NewAppConfig) => {
    await client({
      url: '',
      method: 'POST',
      data: newAppData,
    });
  },
  updateApp: async (client, { appID, etag, appData }: UpdateAppProps) => {
    await client({
      url: `/${appID}`,
      method: 'PUT',
      data: appData,
      headers: {
        'If-Match': etag,
      },
    });
  },
});

export default function useAppApi() {
  const client = useApiClient({ basePath: '/apps' });
  return useApi(client);
}
