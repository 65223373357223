import { defineApi, useApiClient } from '../apiForm/api';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';

export interface CortexKey {
  id: number;
  name: string;
  customerID: string;
  licenseKey: string;
  updatedAt: string;
  validUntil: string;
  links?: any;
}

interface CortexKeyUpdate {
  name: string;
  customerID: string;
  licenseKeyToBeEncrypted: string;
  ivSecret: string;
  keySecret: string;
  validUntil: string;
}

interface GetKeysData {
  cortexKeys: CortexKey[];
  links: any;
}

interface UpdateCortexKeyProps {
  cortexID: number;
  cortexKeyData: CortexKeyUpdate;
}

const useApi = defineApi({
  getKeys: async (client) => {
    const { data } = await client.get<GetKeysData>('');
    return data.cortexKeys;
  },
  getKey: async (client, cortexID: number) => {
    if (!cortexID) return null;
    const { data } = await client.get<CortexKey>(`/${cortexID}`);
    return data;
  },
  createKey: async (client, cortexKeyData: CortexKeyUpdate) => {
    const parsedCortexKeyData = {
      ...cortexKeyData,
      validUntil: new Date(cortexKeyData.validUntil).toISOString(),
    };
    await client({
      url: '',
      method: 'POST',
      data: parsedCortexKeyData,
    });
  },
  updateKey: async (client, { cortexID, cortexKeyData }: UpdateCortexKeyProps) => {
    const data = {
      ...cortexKeyData,
      validUntil: new Date(cortexKeyData.validUntil).toISOString(),
    } as CortexKeyUpdate;

    await client({
      url: `/${cortexID}`,
      method: 'PUT',
      data,
    });
  },
  deleteKey: async (client, cortexID: number) => {
    const { status } = await client({
      url: `/${cortexID}`,
      method: 'DELETE',
      validateStatus: (statusCode) => isSuccessfulOrCustomResponse(statusCode, [409]),
    });
    if (status === 409) {
      throw new Error('Key still in use');
    }
  },
});

export default function useCortexDecoderKeyApi() {
  const client = useApiClient({ basePath: '/portal/cortex-keys' });
  return useApi(client);
}
