import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../../DataDisplay/SortableTable';
import { useResourceTranslator } from '../../apiForm/resource';
import './OrganizationsOverview.css';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useOrganizationsApi from '../../organizations/useOrganizationsApi';

function TableRowEntries({ rowData }: any) {
  return (
    <>
      <TableCell align="left" width="50%">
        {rowData.id}
      </TableCell>
      <TableCell align="left" width="50%">
        {rowData.name || '-/-'}
      </TableCell>
    </>
  );
}

export default function OrganizationsOverview() {
  const api = useOrganizationsApi();
  const t = useResourceTranslator();
  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState<SortableTableDataPoint[]>();
  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      isNumeric: false,
      disablePadding: true,
      displayName: t('organizations.tableColumns.id') || '',
    },
    {
      key: 'name',
      isNumeric: false,
      disablePadding: false,
      displayName: t('organizations.tableColumns.name') || '',
    },
  ], [t]);

  const fetch = useCallback(async () => {
    setOrganizations(await api.getOrganizations(null) || []);
  }, [api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <DocumentTitle translationID="headlines.organizationsOverview" />
      <div className="button-container">
        <Button onClick={() => navigate('new')}>
          <AddIcon />
          {t('organizations.createOrganizationButton')}
        </Button>
      </div>
      <Paper>
        <SortableTable
          data={organizations || []}
          columnsMetadata={headCells}
          CustomRowContent={TableRowEntries}
          title={t('organizations.tableHeader') || ''}
          filterPlaceholder={t('organizations.tableFilterPlaceholder')}
          rowButtons={[
            {
              icon: <VisibilityIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`${row.id}`); },
              tooltip: 'viewOrganization',
            },
            {
              icon: <EditIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`${row.id}/edit`); },
              tooltip: 'editOrganization',
            },
          ]}
        />
      </Paper>
    </>
  );
}
