import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useResourceTranslator } from '../apiForm/resource';
import { declineRelogin } from '../store/authorizationSlice';

export default function LoginConfirmationDialog() {
  const t = useResourceTranslator();
  const reloginDeclined = useSelector((state: any) => state.authorization.reloginDeclined);
  const dispatch = useDispatch();

  return (!reloginDeclined && (
    <Dialog open maxWidth="xs">
      <DialogTitle>
        {t('confirmation.actions.login.title')}
      </DialogTitle>

      <DialogContent dividers>
        <Typography>
          {t('confirmation.actions.login.body')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => { dispatch(declineRelogin()); }}>
          {t('confirmation.buttons.decline')}
        </Button>
        <Button color="primary" variant="contained" href="/login">
          {t('confirmation.buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  ));
}
