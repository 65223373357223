export function parseDatetime(value: string) {
  if (!value) return '';
  const parsedDatetime = new Date(Date.parse(value));
  return parsedDatetime.toLocaleString();
}

export function parseDate(value: string) {
  if (!value) return '';
  const parsedDatetime = new Date(Date.parse(value));
  return parsedDatetime.toLocaleDateString();
}
