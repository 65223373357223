import { useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { FnReturnValue } from './FnReturnValue';
import { FnSecondArg } from './FnSecondArg';

export type ApiServiceDefinition<
  TName extends string = string,
  TParams = any,
  TResult = any,
> = {
  [name in TName]: (client: AxiosInstance, params: TParams) => TResult;
};

export type ApiServices<T> = {
  readonly [P in keyof T]: (params: FnSecondArg<T[P]>) => FnReturnValue<T[P]>;
};

export interface DefineApiOptions<T> {
  services: T;
}

export type UseApiFn<T> = (client: AxiosInstance) => ApiServices<T>;

export default function defineApi<
  T extends ApiServiceDefinition,
>(services: T): UseApiFn<T> {
  return (client: AxiosInstance) => useMemo(() => {
    const enrichedServices: any = {};
    Object.keys(services).forEach((name) => {
      const serviceFn = services[name];
      const wrappedServiceFn = (params: any) => serviceFn(client, params);
      enrichedServices[name] = wrappedServiceFn;
    });
    return enrichedServices;
  }, [client]);
}
