import { useCallback } from 'react';
import { TOptionsBase } from 'i18next';
import { useTranslation } from 'react-i18next';
import useResourceContext from './useResourceContext';

export default function useResourceTranslator() {
  const { t } = useTranslation();

  const {
    name: resourceName,
    preventFallbackToGlobals,
  } = useResourceContext();

  return useCallback(
    (key: any, options?: TOptionsBase) => {
      let defaultValue;
      if (!preventFallbackToGlobals) {
        defaultValue = t(key, options);
      }

      if (!resourceName) {
        return defaultValue;
      }

      return t(
        `${resourceName}.${key}`,
        { ...options, defaultValue },
      );
    },
    [preventFallbackToGlobals, resourceName, t],
  );
}
