import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SidebarProjectRole, SIDEBAR_MASK } from '../../project/useSidebarApi';
import CheckboxField from '../../apiForm/form/input/CheckboxField';

const DEFAULT_VALUES: any = {
};

interface SidebarFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
}

export default function SidebarForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: SidebarFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const checkboxGrid = useMemo(() => (
    defaultValues.projectID && SIDEBAR_MASK.map((entry) => (
      <>
        <TableRow
          sx={{ height: '75px', '&:last-child td, &:last-child th': { border: 0 } }}
          key={`row-${entry.id}`}
        >
          <TableCell key={`header-${entry.id}`}>
            <Stack direction="row" gap="4px">
              {entry.icon && <entry.icon />}
              <Typography sx={{ flexGrow: 1 }}>
                {t(`sidebarView.menuEntries.${entry.id}`, entry.id)}
              </Typography>
              {entry.children && <ExpandLessIcon />}
            </Stack>
          </TableCell>
          {Object.values(SidebarProjectRole).map((role) => (
            <TableCell key={`checkbox-${entry.id}-${role}`}>
              {!entry.nonInteractive && (
                <CheckboxField
                  control={control}
                  name={`relationsForRoles.${role}.${entry.nameOverride || entry.id}`}
                  label=" "
                  disabled={entry.disabledForRoles?.includes(role)}
                />
              )}
            </TableCell>
          ))}
        </TableRow>
        {entry.children?.map((child) => (
          <TableRow
            sx={{ height: '75px', '&:last-child td, &:last-child th': { border: 0 } }}
            key={`row-child-${child.id}`}
          >
            <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }} key="baz">
              <Stack direction="row" gap="4px" sx={{ marginLeft: '64px' }}>
                {child.icon && <child.icon />}
                {t(`sidebarView.menuEntries.${child.id}`, child.id)}
              </Stack>
            </TableCell>
            {Object.values(SidebarProjectRole).map((role) => (
              <TableCell key={`checkbox-child-${child.id}-${role}`}>
                {!child.nonInteractive && (
                  <CheckboxField
                    control={control}
                    name={`relationsForRoles.${role}.${child.nameOverride || child.id}`}
                    label=" "
                    disabled={child.disabledForRoles?.includes(role)}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    ))
  ), [control, defaultValues, t]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="table with permission checkboxes">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: 'rgb(0, 119, 187)', color: 'white' }}>
                Menu Entry
              </TableCell>
              {Object.values(SidebarProjectRole).map((role) => (
                <TableCell key={`header-${role}`}>
                  {t(`sidebarView.menuEntries.${role}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checkboxGrid }
          </TableBody>
        </Table>
      </TableContainer>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
