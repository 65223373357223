import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosInstance } from 'axios';
import useApiClientFactory from '../context/useApiClientFactory';
// @ts-ignore
import { prependApiHost } from '../../../utils/urls';
import logoutInterceptor from '../logoutInterceptor';
import { authorizationError } from '../../../store/authorizationSlice';

export interface UseApiClientOptions {
  basePath?: string;
  configure?: (client: AxiosInstance) => void;
}

export default function useApiClient(
  options: UseApiClientOptions = {},
  createAbortController: () => AbortController = () => new AbortController(),
) {
  const dispatch = useDispatch();
  const clientFactory = useApiClientFactory();

  const abortControllerRef = useRef(createAbortController());

  const authToken = useSelector((state: any) => state.token.value.raw);

  const client = useMemo(
    () => {
      const c = clientFactory();
      c.defaults.baseURL = prependApiHost(options.basePath ?? '');
      c.defaults.signal = abortControllerRef.current.signal;

      if (authToken) {
        c.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        c.interceptors.response.use(
          undefined,
          logoutInterceptor(() => {
            dispatch(authorizationError());
          }),
          { synchronous: true },
        );
      }

      return c;
    },
    [authToken, clientFactory, dispatch, options.basePath],
  );

  // Must run only on unmount, not rerender as it then may unintentionally
  // cancel running requests.
  useEffect(() => () => {
    // BUG this triggers on every api call and is disabled for now
    // (this is safe since the api cannot be unloaded until there are more sub-pages)
    // abortControllerRef.current.abort();
  }, []);

  return client;
}
