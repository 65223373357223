import Stack from '@mui/material/Stack';
import { matchSorter } from 'match-sorter';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';
import { TextField } from '../../apiForm/form/input';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import SearchableSelectField from '../../apiForm/form/input/SearchableSelectField';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import { CortexKey } from '../../license/useCortexDecoderKeyApi';
import { ProjectConfig } from '../../project/useProjectApi';
import AsyncContent from '../../utilComponents/AsyncContent';
import ViewTable from '../../DataDisplay/ViewTable/ViewTable';
import ViewTableRow from '../../DataDisplay/ViewTable/ViewTableRow';
import { parseDate, parseDatetime } from '../../utils/datetimeUtils';

const DEFAULT_VALUES: any = {};

const NONE_CORTEY_KEY: CortexKey = {
  id: null,
  name: '',
  customerID: '',
  validUntil: '',
  updatedAt: '',
} as any as CortexKey;

const searchableProjectSelectSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};
const searchableCortexKeyIdSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'customerID' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'validUntil' },
  ],
};

interface EditAppFormProps extends CustomFormProps {
  availableProjects: ProjectConfig[];
  availableCortexDecoderKeys: CortexKey[];
  getCortexDecoderKeyInfo: (cortexDecoderKeyID: number) => Promise<CortexKey | null>;
  createNewApp?: boolean;
}

export default function EditAppForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  availableProjects,
  availableCortexDecoderKeys,
  getCortexDecoderKeyInfo,
  createNewApp = false,
}: EditAppFormProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  const getCortexKeySelectorLabel = useCallback((cortexKey: CortexKey) => {
    if (cortexKey.id === null) {
      return t('apps.noCortexKeyID');
    }
    return `${cortexKey.id}: ${cortexKey.name} (${t('form.labels.validUntil')}: ${parseDate(cortexKey.validUntil)})`;
  }, [t]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset category="general">
        {createNewApp ? (
          <TextField
            control={control}
            name="idPrefix"
            label={t('apps.idPrefix')}
            required
            rules={{ required: true }}
          />
        ) : (
          <TextField
            control={control}
            name="id"
            disabled
          />
        )}
      </ExpandableFieldset>

      <ExpandableFieldset category="projects" defaultExpanded>
        <ArrayWrapper
          control={control}
          name="projects"
          defaultValue=""
          inlineDeleteButton
          render={(index: number) => (
            <Stack spacing={1} sx={{ width: '100%' }}>
              <SearchableSelectField
                control={control}
                name={`projects[${index}]`}
                sorterOptions={searchableProjectSelectSorterOptions}
                options={availableProjects}
                labelFrom="name"
                valueFrom="id"
                label={t('form.labels.project')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <ExpandableFieldset category="killedVersions">
        <ArrayWrapper
          control={control}
          name="killedVersions"
          inlineDeleteButton
          defaultValue={{}}
          render={(index: number) => (
            <Stack
              spacing={1}
              direction="row"
              sx={{ width: '100%', '> *': { flex: 1 } }}
              alignItems="center"
            >
              <TextField
                control={control}
                name={`killedVersions[${index}].platform`}
              />
              <TextField
                control={control}
                name={`killedVersions[${index}].version`}
              />
              <CheckboxField
                control={control}
                name={`killedVersions[${index}].lessThan`}
                label={t('form.labels.killedVersions.lessThan')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <ExpandableFieldset category="links">
        <ArrayWrapper
          control={control}
          name="links"
          defaultValue={{}}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`links[${index}].rel`}
              />
              <TextField
                control={control}
                name={`links[${index}].href`}
              />
              <CheckboxField
                control={control}
                name={`links[${index}].prefetch`}
                label={t('form.labels.links.prefetch')}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchURL`}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchBaseURL`}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchProperty`}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchFallback`}
              />
              <CheckboxField
                control={control}
                name={`links[${index}].prefetchMergeResultLinks`}
                label={t('form.labels.links.prefetchMergeResultLinks')}
              />
              <TextField
                control={control}
                name={`links[${index}].appVersionLessThan`}
              />
              <TextField
                control={control}
                name={`links[${index}].appVersionGreaterOrEqual`}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <ExpandableFieldset category="cortexDecoderKey">
        <SearchableSelectField
          control={control}
          name="cortexKeyID"
          sorterOptions={searchableCortexKeyIdSorterOptions}
          options={[...availableCortexDecoderKeys, NONE_CORTEY_KEY]}
          labelFrom={getCortexKeySelectorLabel}
          valueFrom="id"
          label={t('form.labels.cortexKeyID')}
        />
        <AsyncContent
          fetch={() => getCortexDecoderKeyInfo(watch('cortexKeyID'))}
          refetch
          render={(cortexDecoderKey: any) => (cortexDecoderKey && (
            <ViewTable headline={t('form.fieldsets.cortexDecoderKeyDetails')}>
              <ViewTableRow title={t('form.labels.id')} value={cortexDecoderKey.id} />
              <ViewTableRow title={t('form.labels.name')} value={cortexDecoderKey.name} />
              <ViewTableRow title={t('form.labels.validUntil')} value={parseDate(cortexDecoderKey.validUntil)} />
              <ViewTableRow title={t('form.labels.updatedAt')} value={parseDatetime(cortexDecoderKey.updatedAt)} />
            </ViewTable>
          ))}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/apps"
      />
    </Form>
  );
}
