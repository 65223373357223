import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useCortexDecoderKeyApi from '../../license/useCortexDecoderKeyApi';
import ViewCortexDecoderKeyForm from './ViewCortexDecoderKeyForm';

export default function ViewCortexDecoderKeyView() {
  const api = useCortexDecoderKeyApi();

  const { keyID } = useParams();

  const getKeyInfo = useCallback(async () => {
    if (!keyID) return null;
    const keyData = await api.getKey(keyID as any as number);
    return keyData;
  }, [api, keyID]);

  return (
    <>
      <DocumentTitle translationID="headlines.viewCortexDecoderKey" />
      <ResourceFormView
        actionName="viewCortexDecoderKey"
        Form={ViewCortexDecoderKeyForm}
        fetch={getKeyInfo}
        submit={() => {}}
      />
    </>
  );
}
