import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { DefaultValues, FieldValues } from 'react-hook-form';
import Container from '@mui/material/Container';
import { FormValidationErrors } from '../api/validation';
import { useResourceTranslator } from '../resource';
import isUnhandledError from './isUnhandledError';
import { CustomFormProps } from '../form';
import LoadableRegion from '../loader/LoadableRegion';
import { withFormValidation } from '../api';
import { useAlert } from '../toast';
import HeadlineWithButtons from './HeadlineWithButtons';

export type AdditionalFormProps<TFormProps extends CustomFormProps> =
  Omit<TFormProps, keyof CustomFormProps>;

export interface ResourceFormViewProps<
  TSubmitResult,
  TFieldValues extends FieldValues,
  TCustomFormProps extends CustomFormProps<TFieldValues>,
> {
  actionName: string;

  headline?: string;

  headlineButtons?: ReactNode;

  additionalContent?: ReactNode;

  Form: ComponentType<TCustomFormProps>;

  FormProps?: AdditionalFormProps<TCustomFormProps>;

  fetch?: () =>
  | DefaultValues<TFieldValues>
  | Promise<DefaultValues<TFieldValues>>;

  onFetched?: (result: DefaultValues<TFieldValues>) => void | Promise<void>;

  onFetchFailed?: (error: Error) => void | Promise<void>;

  submit: (
    formData: FieldValues
  ) => TSubmitResult | Promise<TSubmitResult>;

  onSubmitted?: (result: TSubmitResult) => void | Promise<void>;

  onSubmitFailed?: (error: Error) => void | Promise<void>;
}

export default function ResourceFormView<
  TSubmitResult,
  TFieldValues extends FieldValues = FieldValues,
  TCustomFormProps extends CustomFormProps<TFieldValues> = CustomFormProps<TFieldValues>,
>({
  actionName,
  headline,
  headlineButtons,
  additionalContent,
  Form,
  FormProps,
  fetch,
  onFetched,
  onFetchFailed,
  submit,
  onSubmitFailed,
  onSubmitted,
}: ResourceFormViewProps<TSubmitResult, TFieldValues, TCustomFormProps>) {
  const t = useResourceTranslator();
  const alert = useAlert();

  const [data, setData] = useState<DefaultValues<TFieldValues>>();
  const [isFetching, setIsFetching] = useState(false);

  const [validationErrors, setValidationErrors] = useState<FormValidationErrors>({});

  const [criticalError, setCriticalError] = useState('');
  const [hasFetchError, setHasFetchError] = useState(false);

  const handleSubmit = useCallback(async (formData: FieldValues) => {
    setValidationErrors({});

    try {
      const result = await withFormValidation(submit(formData));
      if (result.isErr()) {
        setValidationErrors(result.error);
        return;
      }

      await onSubmitted?.(result.value);
      alert.success({ actionName });
    } catch (e) {
      if (!(e instanceof Error) || isUnhandledError(e)) return;
      await onSubmitFailed?.(e);
      alert.error({ message: e.message });
    }
  }, [actionName, alert, onSubmitFailed, onSubmitted, submit]);

  // Keeps track whether fetch is already running for the component instance.
  // Prevents fetching multiple times when rerendering while still fetching.
  const fetchingRef = useRef(false);

  useEffect(() => {
    if (!fetch || fetchingRef.current) return;

    const fetchData = async () => {
      fetchingRef.current = true;
      setIsFetching(true);
      setCriticalError('');
      try {
        setData(await fetch());
        setIsFetching(false);
      } catch (e) {
        if (!(e instanceof Error) || isUnhandledError(e)) return;
        setCriticalError(e.message);
        setIsFetching(false);
        setHasFetchError(true);
        await onFetchFailed?.(e);
      } finally {
        fetchingRef.current = false;
      }
    };

    fetchData();
  }, [fetch, onFetchFailed]);

  useEffect(() => {
    if (!data) return;
    onFetched?.(data);
  }, [data, onFetched]);

  const formProps = useMemo(
    () => ({
      ...FormProps,
      actionName,
      defaultValues: data,
      errors: validationErrors,
      onSubmit: handleSubmit,
    } as TCustomFormProps),
    [FormProps, actionName, data, validationErrors, handleSubmit],
  );

  return (
    <Container maxWidth="lg">
      {(headline || actionName) && (
        <HeadlineWithButtons
          buttons={headlineButtons}
        >
          {headline || t(`headlines.${actionName}`)}
        </HeadlineWithButtons>
      )}

      {additionalContent}

      <Stack spacing={4}>
        {criticalError && (
          <Alert severity="error">
            <AlertTitle>{t('alert.criticalError')}</AlertTitle>
            {criticalError}
          </Alert>
        )}

        <LoadableRegion loading={isFetching}>
          {!hasFetchError && (<Form {...formProps} />)}
        </LoadableRegion>
      </Stack>
    </Container>
  );
}
