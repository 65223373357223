import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Router from './router/Router';
import theme from './theme';
import { getToken } from './utils/TokenUtils';
import store from './store/store';
import { acquireToken } from './store/tokenSlice';
import { authorize } from './store/authorizationSlice';
import { ToastProvider } from './apiForm/toast';
import './translations/i18n';

const token = getToken();
if (token.raw === '' && !window.location.href.match(/\/login/)) {
  window.location = `/login?backTo=${encodeURIComponent(window.location.href)}`;
}
store.dispatch(acquireToken(token));
store.dispatch(authorize());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <Container maxWidth="false" sx={{ maxWidth: '2000px' }}>
            <Router />
          </Container>
        </ToastProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
