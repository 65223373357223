import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';

const DEFAULT_VALUES: any = {
};

interface EditCortexDecoderKeyFormProps extends CustomFormProps {
  onKeyDelete?: () => Promise<void>;
}

export default function EditCortexDecoderKeyForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  onKeyDelete,
}: EditCortexDecoderKeyFormProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const confirmDeleteKey = useCallback(async () => {
    setDeleteDialogOpen(false);
    await onKeyDelete?.();
  }, [onKeyDelete]);

  return (
    <>
      <Dialog open={deleteDialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('cortexDecoderKeys.deleteKey.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('cortexDecoderKeys.deleteKey.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setDeleteDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={confirmDeleteKey} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset category="general">
          {defaultValues.id && (
            <TextField
              control={control}
              name="id"
              disabled
            />
          )}
          <TextField
            control={control}
            name="name"
            required
            rules={{ required: true }}
          />
          <TextField
            control={control}
            name="customerID"
            required
            rules={{ required: true }}
          />
          <TextField
            control={control}
            name="licenseKeyToBeEncrypted"
            helperText={t('cortexDecoderKeys.helperText.licenseKey')}
            required
            rules={{ required: true }}
          />
          <TextField
            control={control}
            name="ivSecret"
            helperText={t('cortexDecoderKeys.helperText.ivSecret')}
            required
            rules={{ required: true }}
          />
          <TextField
            control={control}
            name="keySecret"
            helperText={t('cortexDecoderKeys.helperText.keySecret')}
            required
            rules={{ required: true }}
          />
          <TextField
            control={control}
            type="date"
            name="validUntil"
            InputLabelProps={{ shrink: true }}
            helperText={t('cortexDecoderKeys.helperText.validUntil')}
            required
            rules={{ required: true }}
          />
        </Fieldset>

        <DefaultFormActions
          formState={formState}
          cancelNavigationTarget="/cortex-decoder-keys"
          additionalButtons={onKeyDelete && (
            <Button
              aria-label="delete"
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => { setDeleteDialogOpen(true); }}
            >
              {t('button.remove')}
            </Button>
          )}
        />
      </Form>
    </>
  );
}
