import React, { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useCortexDecoderKeyApi from '../../license/useCortexDecoderKeyApi';
import EditCortexDecoderKeyForm from './EditCortexDecoderKeyForm';

export default function CreateCortexDecoderKeyView() {
  const api = useCortexDecoderKeyApi();
  const navigate = useNavigate();

  const createKeyInfo = useCallback(async (data: FieldValues) => {
    await api.createKey(data as any);
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="headlines.createCortexDecoderKey" />
      <ResourceFormView
        actionName="createCortexDecoderKey"
        Form={EditCortexDecoderKeyForm}
        fetch={() => ({})}
        submit={createKeyInfo}
        onSubmitted={() => navigate('/cortex-decoder-keys')}
      />
    </>
  );
}
