import React, {} from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import TranslationSwitch from '../translations/LanguageSwitch';

type TopbarProps = {
  drawerWidth: number,
  scrollWithView?: boolean,
  onDrawerToggle: () => void,
};

export default function Topbar({ drawerWidth, scrollWithView, onDrawerToggle }: TopbarProps) {
  const token = useSelector((state: any) => state.token.value);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: React.BaseSyntheticEvent) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position={scrollWithView ? 'absolute' : 'fixed'}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        heigt: '76px',
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <TranslationSwitch />

        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Typography sx={{ color: 'white' }}>
              {token.grants.email}
            </Typography>
            <Avatar alt="token.grants.email" src={token.grants.picture} imgProps={{ referrerPolicy: 'no-referrer' }} />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableScrollLock
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem component={Button} href="/login?logout=true" sx={{ textTransform: 'none' }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
