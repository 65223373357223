import { configureStore } from '@reduxjs/toolkit';
import tokenReducer, { TokenState } from './tokenSlice';
import authorizationReducer, { AuthorizationState } from './authorizationSlice';

export interface RootState {
  authorization: AuthorizationState;
  token: TokenState;
}

export function buildStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: {
      token: tokenReducer,
      authorization: authorizationReducer,
    },
    preloadedState: {
      ...(preloadedState ?? {}),
    },
  });
}

const store = buildStore();
export default store;

export type AppStore = ReturnType<typeof buildStore>;

export type AppDispatch = typeof store.dispatch;
