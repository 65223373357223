import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCheckoutApi from '../../project/useCheckoutApi';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import PaymentConfigurationForm from './PaymentConfigurationForm';

export default function PaymentConfigurationView() {
  const api = useCheckoutApi();

  const routerParams = useParams();
  const projectId = routerParams.projectId || '';

  const [etag, setEtag] = useState('');

  const getProjectsInfo = useCallback(async () => {
    const [responseData, responseEtag] = await api.getCheckoutConfig({ projectId });
    setEtag(responseEtag);
    return responseData;
  }, [api, projectId]);

  return (
    <>
      <DocumentTitle translationID="headlines.checkoutView" />
      <ResourceFormView
        actionName="checkout"
        Form={PaymentConfigurationForm}
        fetch={getProjectsInfo}
        submit={(data) => api.updateCheckoutConfig({ projectId, data, etag })}
        onSubmitted={getProjectsInfo}
      />
    </>
  );
}
