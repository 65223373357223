import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useSidebarApi, { getSidebarDefaults } from '../../project/useSidebarApi';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import SidebarForm from './SidebarForm';

export default function SidebarView() {
  const api = useSidebarApi();
  const { t } = useTranslation();

  const { projectId } = useParams();

  const [currentEtag, setCurrentEtag] = useState('');
  const [initLayout, setInitLayout] = useState(false);

  const getLayout = useCallback(async () => {
    let sidebarLayout = await api.getSidebarLayout({ projectId });
    if (!sidebarLayout) {
      setInitLayout(true);
      sidebarLayout = getSidebarDefaults(projectId);
    }
    const { data, etag } = sidebarLayout;
    setCurrentEtag(etag || '');
    return data;
  }, [api, projectId]);

  return (
    <>
      <DocumentTitle translationID="sidebarView.headline" />
      <div>
        {initLayout && (
          <Button
            variant="contained"
            sx={{
              position: 'absolute', left: '50%', transform: 'translate(-50%, 400%)', zIndex: 10,
            }}
            onClick={() => setInitLayout(false)}
          >
            {t('sidebarView.initLayoutHint')}
          </Button>
        )}
        <div style={{ ...(initLayout && { filter: 'blur(1.5px)', opacity: '.5' }) }}>
          <ResourceFormView
            actionName="sidebar"
            Form={SidebarForm}
            fetch={getLayout}
            submit={(data) => api.updateSidebarLayout({
              layout: data,
              etag: currentEtag,
              projectId,
            })}
            // NOTE this is done to get a new etag to allow further submits
            // HACK the same this works for the ProjectView without the any
            onSubmitted={getLayout as any}
          />
        </div>
      </div>
    </>
  );
}
