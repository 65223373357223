import React from 'react';
import Stack from '@mui/material/Stack';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { NumberField, SelectOptionsField, TextField } from '../../apiForm/form/input';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import { Organization } from '../../organizations/useOrganizationsApi';

const DEFAULT_VALUES: any = {
};

interface ProjectFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
  createNewProject?: boolean;
  availableOrganizations?: Organization[];
}

export default function ProjectForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
  createNewProject,
  availableOrganizations,
}: ProjectFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="general">
        {createNewProject && (
          <TextField
            control={control}
            name="idPrefix"
            disabled={readonly}
            required
            helperText={t('helperText.idPrefix')}
          />
        )}
        <TextField
          control={control}
          name="name"
          disabled={readonly}
          required
        />
        <TextField
          control={control}
          name="displayName"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="brandID"
          disabled={readonly}
        />
        {!!availableOrganizations?.length && (
          <SelectOptionsField
            control={control}
            name="organizationID"
            disabled={readonly}
            options={availableOrganizations}
            valueFrom={(orga) => orga.id}
            labelFrom={(orga) => orga.name}
            includeEmpty
            helperText={createNewProject ? '' : t('project.helperText.organizationID')}
          />
        )}
        <TextField
          control={control}
          name="locale"
          disabled={readonly}
        />
        <CheckboxField
          control={control}
          name="enableCheckout"
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="company">
        <TextField
          control={control}
          name="companyName"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="companyAddress.street"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="companyAddress.city"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="companyAddress.zip"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="companyAddress.country"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="companyAddress.phone"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="companyAddress.email"
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="products">
        <CheckboxField
          control={control}
          name="verifyInternationalEanChecksum"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="scanFormats"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="barcodeDetector"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="expectedBarcodeWidth"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="checkoutLimits.checkoutNotAvailable"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="checkoutLimits.notAllMethodsAvailable"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="searchableTemplates"
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="finance">
        <TextField
          control={control}
          name="taxNumber"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="taxID"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="currency"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="decimalDigits"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="roundingMode"
          disabled={readonly}
        />
        <CheckboxField
          control={control}
          name="displayNetPrice"
          disabled={readonly}
        />
        <CheckboxField
          control={control}
          name="useGermanPrintPrefix"
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="customerCards">
        <TextField
          control={control}
          name="customerCards.required"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="customerCards.accepted"
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="qrCodeOffline">
        <TextField
          control={control}
          name="qrCodeOffline.format"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.prefix"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.separator"
          multiline
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.suffix"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="qrCodeOffline.maxCodes"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.finalCode"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.manualDiscountFinalCode"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.nextCode"
          disabled={readonly}
        />
        <TextField
          control={control}
          name="qrCodeOffline.nextCodeWithCheck"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="qrCodeOffline.maxSizeMM"
          disabled={readonly}
        />
        <NumberField
          control={control}
          name="qrCodeOffline.maxChars"
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="texts">
        <TextField
          control={control}
          name="texts.sepaMandate"
          multiline
          disabled={readonly}
        />
        <TextField
          control={control}
          name="texts.sepaMandateShort"
          multiline
          disabled={readonly}
        />
      </Fieldset>

      <Fieldset category="links">
        <ArrayWrapper
          control={control}
          name="links"
          defaultValue={{}}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`links[${index}].rel`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].href`}
                disabled={readonly}
              />
              <CheckboxField
                control={control}
                name={`links[${index}].prefetch`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchURL`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchBaseURL`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchProperty`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].prefetchFallback`}
                disabled={readonly}
              />
              <CheckboxField
                control={control}
                name={`links[${index}].prefetchMergeResultLinks`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].onlyForApps`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].appVersionLessThan`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`links[${index}].appVersionGreaterOrEqual`}
                disabled={readonly}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <Fieldset category="priceOverrideCodes">
        <ArrayWrapper
          control={control}
          name="priceOverrideCodes"
          defaultValue={{}}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`priceOverrideCodes[${index}].id`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`priceOverrideCodes[${index}].template`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`priceOverrideCodes[${index}].transmissionTemplate`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`priceOverrideCodes[${index}].transmissionCode`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`priceOverrideCodes[${index}].lookupTemplate`}
                disabled={readonly}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <Fieldset category="codeTemplates">
        <ArrayWrapper
          control={control}
          name="codeTemplates"
          defaultValue={{}}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`codeTemplates[${index}].id`}
                disabled={readonly}
              />
              <TextField
                control={control}
                name={`codeTemplates[${index}].pattern`}
                disabled={readonly}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
