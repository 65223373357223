import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useCortexDecoderKeyApi from '../../license/useCortexDecoderKeyApi';
import EditCortexDecoderKeyForm from './EditCortexDecoderKeyForm';
import useAlert from '../../apiForm/toast/useAlert';

export default function ViewCortexDecoderKeyView() {
  const api = useCortexDecoderKeyApi();
  const navigate = useNavigate();
  const alert = useAlert();

  const { keyID } = useParams();

  const getKeyInfo = useCallback(async () => {
    if (!keyID) return null;
    const keyData = await api.getKey(keyID as any as number);
    return keyData;
  }, [api, keyID]);

  const updateKeyInfo = useCallback(async (data: FieldValues) => {
    if (!keyID) return;
    await api.updateKey({
      cortexID: keyID as any as number,
      cortexKeyData: data as any,
    });
  }, [api, keyID]);

  const deleteKey = useCallback(async () => {
    if (!keyID) return;
    try {
      await api.deleteKey(keyID as any as number);
    } catch (error) {
      alert.error({ actionName: 'cortexDecoderKeyConflict' });
      return;
    }
    navigate('/cortex-decoder-keys');
    alert.success({ actionName: 'deleteCortexDecoderKey' });
  }, [alert, api, keyID, navigate]);

  return (
    <>
      <DocumentTitle translationID="headlines.cortexDecoderKeyView" />
      <ResourceFormView
        actionName="editCortexDecoderKey"
        Form={EditCortexDecoderKeyForm}
        FormProps={{
          onKeyDelete: deleteKey,
        }}
        fetch={getKeyInfo}
        submit={updateKeyInfo}
        onSubmitted={() => navigate('/cortex-decoder-keys')}
      />
    </>
  );
}
