import React, { ReactNode, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/material/styles/createTheme';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { useResourceTranslator } from '../apiForm/resource';

type SidebarMenuGroupProps = {
  linkText?: string,
  translationId?: string,
  icon?: React.ReactNode,
  disabled?: boolean,
  children?: ReactNode,
};

const styles = (theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  linkActive: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  menuItem: {
    padding: 0,
    height: 'auto',
    display: 'flex',
  },
});

export default function SidebarMenuGroup({
  linkText,
  translationId,
  icon,
  disabled,
  children,
}: SidebarMenuGroupProps) {
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const classes = styles(theme);

  const t = useResourceTranslator();
  const listItemText = linkText || t(translationId) || '';

  return (
    <>
      <ListItem disablePadding style={classes.menuItem}>
        <ListItemButton onClick={() => { setOpen((o) => !o); }} disabled={disabled}>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography>{listItemText}</Typography>}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            marginLeft: '4px',
            backgroundColor: 'rgba(0,0,0,0.04)',
          }}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
}
