import React, { ReactNode, SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { matchSorter, MatchSorterOptions } from 'match-sorter';

interface SearchableSelectProps<T> extends Record<string, any> {
  options: T[];
  onChange: (
    event: SyntheticEvent,
    value: T | T[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => void
  sorterOptions: MatchSorterOptions;
  label?: string | ReactNode | undefined;
  defaultValue?: T;
  multiple?: boolean;
  labelFrom?: keyof T;
  keyFrom?: keyof T;
}

function SearchableSelect<T extends Record<string, any>>({
  options,
  onChange,
  sorterOptions,
  label,
  defaultValue,
  multiple = false,
  labelFrom = 'title',
  keyFrom = 'id',
  ...props
}: SearchableSelectProps<T>) {
  const filterOptions = (opts: T[], { inputValue }: { inputValue: string }) => (
    matchSorter(opts, inputValue, sorterOptions) as T[]
  );

  return (
    <Autocomplete
      id="project-filter-input"
      data-testid="searchable-select"
      filterOptions={filterOptions}
      options={options}
      getOptionLabel={(option) => (option?.[labelFrom] as string || '')}
      renderOption={(renderProps, option) => (
        <li {...renderProps} key={option[keyFrom] as string}>
          {option[labelFrom]}
        </li>
      )}
      renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
      onChange={onChange}
      defaultValue={defaultValue} // FIXME change component to being controlled to suppress warning
      multiple={multiple}
      filterSelectedOptions={multiple}
      limitTags={1}
      {...props}
    />
  );
}

export default SearchableSelect;
