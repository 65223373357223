import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useApiClient,
} from '../apiForm/api';
import { SortableTableDataPoint } from '../DataDisplay/SortableTable';
import { commaSeparatedStringToArray } from '../utils/apiStringUtils';
import removeUndefinedObjectEntries from '../utils/apiObjectUtils';

export interface ProjectConfig extends SortableTableDataPoint {
  idPrefix?: string,
  id: string,
  name: string,
  displayName: string,
  brandID: string,
  organizationID: string,
  companyName: string,
  companyAddress: {
    street: string,
    zip: string | number,
    city: string,
    country: string,
    phone: string,
    email: string
  },
  taxNumber: number,
  taxID: string,
  currency: string,
  locale: string,
  decimalDigits: number,
  roundingMode: string,
  verifyInternalEanChecksum: boolean,
  scanFormats: string[],
  barcodeDetector: string,
  expectedBarcodeWidth: number,
  displayNetPrice: boolean,
  qrCodeOffline: {
    format: string,
    prefix: string,
    separator: string,
    suffix: string,
    maxCodes: number,
    finalCode: string | number,
    manualDiscountFinalCode: string | number,
    nextCode: string | number,
    nextCodeWithCheck: string | number,
    maxSizeMM: number,
    maxChars: number
  },
  useGermanPrintPrefix: boolean,
  texts: {
    [key: string]: string
  },
  links: Array<{
    rel: string,
    href: string,
    prefetch: boolean,
    prefetchURL: string,
    prefetchBaseURL: string,
    prefetchProperty: string,
    prefetchFallback: string,
    prefetchMergeResultLinks: boolean,
    onlyForApps: string[],
    appVersionLessThan: string,
    appVersionGreaterOrEqual: string
  }>,
  enableCheckout: boolean,
  checkoutLimits: {
    checkoutNotAvailable: number,
    notAllMethodsAvailable: number
  },
  searchableTemplates: string[],
  codeTemplates: Array<{
    id: string,
    pattern: string
  }>,
  priceOverrideCodes: Array<{
    id: string,
    template: string,
    transmissionTemplate: string,
    transmissionCode: string | number,
    lookupTemplate: string
  }>,
  customerCards: {
    required: string,
    accepted: string[]
  }
}

const sanitizeData = (data: FieldValues) => {
  const scanFormats = typeof data.scanFormats === 'string' ? commaSeparatedStringToArray(data.scanFormats) : data.scanFormats;
  const searchableTemplates = typeof data.searchableTemplates === 'string' ? commaSeparatedStringToArray(data.searchableTemplates) : data.searchableTemplates;
  const accepted = typeof data.customerCards?.accepted === 'string' ? commaSeparatedStringToArray(data.customerCards.accepted) : data.customerCards?.accepted;
  let links = data.links?.map((link:any) => {
    const onlyForApps = typeof link.onlyForApps === 'string' ? commaSeparatedStringToArray(link.onlyForApps) : link.onlyForApps;
    return { ...link, onlyForApps };
  });

  // NOTE unregistered entries from the form controller are still there
  // but undefined and therefore need to be filtered
  links = links?.filter((i:any) => i !== undefined) || [];
  const priceOverrideCodes = data.priceOverrideCodes?.filter((i:any) => i !== undefined) || [];
  const codeTemplates = data.codeTemplates?.filter((i:any) => i !== undefined) || [];

  const decimalDigits = parseInt(data.decimalDigits, 10);

  const texts = data.texts || {};

  let cleanedData:FieldValues = {
    ...data,
    scanFormats,
    searchableTemplates,
    customerCards: { ...data.customerCards, accepted },
    links,
    codeTemplates,
    priceOverrideCodes,
    decimalDigits,
    texts,
  };
  delete cleanedData.id;
  cleanedData = removeUndefinedObjectEntries(cleanedData);

  return cleanedData;
};

export interface ProjectParams {
  projectId: string,
  data: FieldValues,
  etag?: string,
}

export interface NewProjectParams {
  data: FieldValues,
}

const useApi = defineApi({
  getProjectInfo: async (client, { projectId }: { projectId: string }) => {
    const response:any = await client({
      url: `projects/${projectId}`,
      method: 'GET',
    });
    return [response.data, response.headers.etag];
  },
  updateProjectInfo: async (client, { projectId, data, etag }: ProjectParams) => {
    const cleanedData = sanitizeData(data);

    await client({
      url: `projects/${projectId}`,
      method: 'PUT',
      data: cleanedData,
      headers: {
        'If-Match': etag,
      },
    });
  },
  createProject: async (client, { data }: NewProjectParams) => {
    if (!data.name) {
      throw new Error('Project name is required');
    }
    if (!data.idPrefix) {
      throw new Error('Id prefix is required');
    }
    if (data.idPrefix.length > 25) {
      throw new Error('Id prefix must not have more than 10 characters');
    }

    const cleanedData = sanitizeData(data);

    const response = await client({
      url: '/projects',
      method: 'POST',
      data: cleanedData,
      headers: {
        'If-Match': '"*"',
      },
    });

    return response.data.id;
  },
});

export default function useProjectApi() {
  const client = useApiClient({ basePath: '' });
  return useApi(client);
}
