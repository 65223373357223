import React, {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import uniqueId from 'lodash/uniqueId';
import ToastContext from './ToastContext';
import { ToastProps } from './ToastProps';

const DEFAULT_AUTO_HIDE_DURATION = 4000;

export interface ToastProviderProps {
  children: ReactNode;
}

interface ToastInfo {
  key: string;
  props: ToastProps;
}

function SlideUp(props: SlideProps) {
  return <Slide direction="up" {...props} />;
}

// Roughly adopted from
// https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
export default function ToastProvider({ children }: ToastProviderProps) {
  const [toastInfos, setToastInfos] = useState<ToastInfo[]>([]);
  const [currentToastInfo, setCurrentToastInfo] = useState<ToastInfo>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!toastInfos.length) return;

    if (!currentToastInfo) {
      setCurrentToastInfo({ ...toastInfos[0] });
      setToastInfos((prev) => prev.slice(1));
      setOpen(true);
    } else if (currentToastInfo && open) {
      setOpen(false);
    }
  }, [open, toastInfos, currentToastInfo]);

  const handleClosed = () => {
    setCurrentToastInfo(undefined);
  };

  const show = useCallback((props: SnackbarProps) => {
    setToastInfos((prevProps) => [
      ...prevProps,
      { key: uniqueId('toast_'), props },
    ]);
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  const callbacks = useMemo(() => ({
    show,
    hide,
  }), [hide, show]);

  return (
    <ToastContext.Provider value={callbacks}>
      {children}

      <Snackbar
        key={currentToastInfo?.key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
        TransitionProps={{ onExited: handleClosed }}
        TransitionComponent={SlideUp}
        onClose={hide}
        open={open}
        {...currentToastInfo?.props}
      />
    </ToastContext.Provider>
  );
}
