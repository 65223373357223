import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useApiClient, { UseApiClientOptions } from './useApiClient';

export default function useProjectSpecificApiClient(options: UseApiClientOptions = {}) {
  const { projectId } = useParams();

  const opts = useMemo(
    () => {
      const basePath = `/${projectId}${options.basePath || ''}`;
      return { ...options, basePath };
    },
    [options, projectId],
  );

  return useApiClient(opts);
}
