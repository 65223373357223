import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProjectApi from '../../project/useProjectApi';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import ProjectForm from './ProjectForm';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useOrganizationsApi, { Organization } from '../../organizations/useOrganizationsApi';

export default function ProjectView() {
  const api = useProjectApi();
  const organizationApi = useOrganizationsApi();
  const navigate = useNavigate();

  const routerParams = useParams();
  const clonedProjectId = routerParams.projectId || '';

  const projectId = useRef<string | undefined>(undefined);
  const [availableOrganizations, setAvailableOrganizations] = useState<Organization[]>([]);

  const fetch = useCallback(async () => {
    setAvailableOrganizations(await organizationApi.getOrganizations(null) || []);

    if (!clonedProjectId) {
      return {};
    }

    const [responseData] = await api.getProjectInfo({ projectId: clonedProjectId });
    responseData.name = '';

    return responseData;
  }, [api, clonedProjectId, organizationApi]);

  const createProject = useCallback(async (data: any) => {
    const newProjectId = await api.createProject({ data });
    projectId.current = newProjectId;
  }, [api]);

  const navigateToProjectPage = useCallback(() => {
    if (!projectId.current) {
      navigate('/');
    }
    navigate(`/projects/${projectId.current}`);
  }, [navigate]);

  return (
    <>
      <DocumentTitle translationID="headlines.newProjectView" />
      <ResourceFormView
        actionName="newProjectView"
        Form={ProjectForm}
        FormProps={{
          createNewProject: true,
          availableOrganizations,
        }}
        fetch={fetch}
        submit={(data) => createProject(data)}
        onSubmitted={() => navigateToProjectPage()}
      />
    </>
  );
}
