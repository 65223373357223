import React from 'react';
import Stack from '@mui/material/Stack';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { NumberField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

const DEFAULT_VALUES: any = {
};

interface CheckoutFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
}

export default function PaymentConfigurationForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.defaultPaymentConfigurations')}
        sublegend={t('checkoutConfig.sublegend.defaultPaymentConfigurations')}
      >
        <ArrayWrapper
          control={control}
          name="defaultPaymentConfigurations"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.paymentConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.paymentConfig.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].methodID`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.methodID')}
              />
              <TextField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].methodName`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.methodName')}
                helperText={t('checkoutConfig.helperText.paymentConfig.methodName')}
              />
              <TextField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].baseURI`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.baseURI')}
                helperText={t('checkoutConfig.helperText.paymentConfig.baseURI')}
              />
              <TextField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].token`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.token')}
              />
              <CheckboxField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].originVerificationRequired`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.paymentConfig.originVerificationRequired')} (${t('checkoutConfig.helperText.paymentConfig.originVerificationRequired')})`}
              />
              <NumberField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].limit`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.paymentConfig.limit')}
                helperText={t('checkoutConfig.helperText.paymentConfig.limit')}
                rules={{ pattern: /^[0-9]+$/ }}
              />
              <TextField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].acceptedOriginTypes`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.paymentConfig.acceptedOriginTypes')} (comma separated)`}
                helperText={t('checkoutConfig.helperText.paymentConfig.acceptedOriginTypes')}
              />
              <TextField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].providerName`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.providerName')}
              />
              <CheckboxField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].triggerReceiptGeneration`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.paymentConfig.triggerReceiptGeneration')} (${t('checkoutConfig.helperText.paymentConfig.triggerReceiptGeneration')})`}
              />
              <CheckboxField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].verifiesAge`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.paymentConfig.verifiesAge')} (${t('checkoutConfig.helperText.paymentConfig.verifiesAge')})`}
              />
              <CheckboxField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].manageSepaMandateByProvider`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.paymentConfig.manageSepaMandateByProvider')}`}
              />
              <CheckboxField
                control={control}
                name={`defaultPaymentConfigurations.[${index}].isTesting`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.paymentConfig.isTesting')} (${t('checkoutConfig.helperText.paymentConfig.isTesting')})`}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <ExpandableFieldset
        legend={t('checkoutConfig.legend.paymentConfigurations')}
        sublegend={t('checkoutConfig.sublegend.paymentConfigurations')}
      >
        <ArrayWrapper
          control={control}
          name="paymentConfigurations"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.shopConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.shopConfig.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`paymentConfigurations.[${index}].shopID`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.shopID')}
              />
              <ArrayWrapper
                control={control}
                name={`paymentConfigurations.[${index}].configurations`}
                defaultValue={{}}
                addLabel={t('checkoutConfig.arrayWrapper.paymentConfig.add')}
                removeLabel={t('checkoutConfig.arrayWrapper.paymentConfig.remove')}
                indent
                render={(index2: number) => (
                  <Stack spacing={1}>
                    <TextField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].methodID`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.paymentConfig.methodID')}
                    />
                    <TextField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].methodName`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.paymentConfig.methodName')}
                      helperText={t('checkoutConfig.helperText.paymentConfig.methodName')}
                    />
                    <TextField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].baseURI`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.paymentConfig.baseURI')}
                      helperText={t('checkoutConfig.helperText.paymentConfig.baseURI')}
                    />
                    <TextField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].token`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.paymentConfig.token')}
                    />
                    <CheckboxField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].originVerificationRequired`}
                      disabled={readonly}
                      label={`${t('checkoutConfig.label.paymentConfig.originVerificationRequired')} (${t('checkoutConfig.helperText.paymentConfig.originVerificationRequired')})`}
                    />
                    <NumberField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].limit`}
                      disabled={readonly}
                      optional
                      label={t('checkoutConfig.label.paymentConfig.limit')}
                      helperText={t('checkoutConfig.helperText.paymentConfig.limit')}
                      rules={{ pattern: /^[0-9]+$/ }}
                    />
                    <TextField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].acceptedOriginTypes`}
                      disabled={readonly}
                      label={`${t('checkoutConfig.label.paymentConfig.acceptedOriginTypes')} (comma separated)`}
                      helperText={t('checkoutConfig.helperText.paymentConfig.acceptedOriginTypes')}
                    />
                    <TextField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].providerName`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.paymentConfig.providerName')}
                    />
                    <CheckboxField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].triggerReceiptGeneration`}
                      disabled={readonly}
                      label={`${t('checkoutConfig.label.paymentConfig.triggerReceiptGeneration')} (${t('checkoutConfig.helperText.paymentConfig.triggerReceiptGeneration')})`}
                    />
                    <CheckboxField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].verifiesAge`}
                      disabled={readonly}
                      label={`${t('checkoutConfig.label.paymentConfig.verifiesAge')} (${t('checkoutConfig.helperText.paymentConfig.verifiesAge')})`}
                    />
                    <CheckboxField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].manageSepaMandateByProvider`}
                      disabled={readonly}
                      label={`${t('checkoutConfig.label.paymentConfig.manageSepaMandateByProvider')}`}
                    />
                    <CheckboxField
                      control={control}
                      name={`paymentConfigurations.[${index}].configurations.[${index2}].isTesting`}
                      disabled={readonly}
                      label={`${t('checkoutConfig.label.paymentConfig.isTesting')} (${t('checkoutConfig.helperText.paymentConfig.isTesting')})`}
                    />
                  </Stack>
                )}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>
      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
