/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface AuthorizationState {
  authorized: boolean;
  reloginDeclined: boolean;
}

const initialState:AuthorizationState = {
  authorized: false,
  reloginDeclined: false,
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    authorize: (state) => {
      state.authorized = true;
      state.reloginDeclined = false;
    },
    authorizationError: (state) => {
      state.authorized = false;
      state.reloginDeclined = false;
    },
    declineRelogin: (state) => {
      state.reloginDeclined = true;
    },
  },
});

export default authorizationSlice.reducer;

export const { authorize, authorizationError, declineRelogin } = authorizationSlice.actions;
