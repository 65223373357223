import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../../DataDisplay/SortableTable';
import { useResourceTranslator } from '../../apiForm/resource';
import './CortexDecoderKeysOverview.css';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import useCortexDecoderKeyApi from '../../license/useCortexDecoderKeyApi';
import { parseDate, parseDatetime } from '../../utils/datetimeUtils';

function TableRowEntries({ rowData }: any) {
  return (
    <>
      <TableCell align="left">
        {rowData.id}
      </TableCell>
      <TableCell align="left">
        {rowData.name || '-/-'}
      </TableCell>
      <TableCell align="left">
        {rowData.customerID || '-/-'}
      </TableCell>
      <TableCell align="left">
        {parseDate(rowData.validUntil)}
      </TableCell>
      <TableCell align="left">
        {parseDatetime(rowData.updatedAt)}
      </TableCell>
    </>
  );
}

export default function CortexDecoderKeysOverview() {
  const api = useCortexDecoderKeyApi();
  const t = useResourceTranslator();
  const navigate = useNavigate();

  const [keys, setKeys] = useState<SortableTableDataPoint[]>();
  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      isNumeric: false,
      disablePadding: true,
      displayName: t('cortexDecoderKeys.tableColumns.id') || '',
    },
    {
      key: 'name',
      isNumeric: false,
      disablePadding: false,
      displayName: t('cortexDecoderKeys.tableColumns.name') || '',
    },
    {
      key: 'customerID',
      isNumeric: false,
      disablePadding: false,
      displayName: t('cortexDecoderKeys.tableColumns.customerID') || '',
    },
    {
      key: 'validUntil',
      isNumeric: false,
      type: 'datetime',
      disablePadding: false,
      displayName: t('cortexDecoderKeys.tableColumns.validUntil') || '',
    },
    {
      key: 'updatedAt',
      isNumeric: false,
      type: 'datetime',
      disablePadding: false,
      displayName: t('cortexDecoderKeys.tableColumns.updatedAt') || '',
    },
  ], [t]);

  const fetch = useCallback(async () => {
    setKeys(await api.getKeys(null) || []);
  }, [api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <DocumentTitle translationID="headlines.cortexDecoderKeysOverview" />
      <div className="button-container">
        <Button onClick={() => navigate('new')}>
          <AddIcon />
          {t('cortexDecoderKeys.createKeyButton')}
        </Button>
      </div>
      <Paper>
        <SortableTable
          data={keys || []}
          columnsMetadata={headCells}
          CustomRowContent={TableRowEntries}
          title={t('cortexDecoderKeys.tableHeader') || ''}
          filterPlaceholder={t('cortexDecoderKeys.tableFilterPlaceholder')}
          rowButtons={[
            {
              icon: <VisibilityIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`${row.id}`); },
              tooltip: 'viewKey',
            },
            {
              icon: <EditIcon fontSize="small" />,
              callback: (row: SortableTableDataPoint) => { navigate(`${row.id}/edit`); },
              tooltip: 'editKey',
            },
          ]}
        />
      </Paper>
    </>
  );
}
