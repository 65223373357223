import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

const HeadlineWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(4),
}));

export interface HeadlineWithButtonsProps {
  buttons?: ReactNode;
  children: ReactNode;
}

export default function HeadlineWithButtons({
  buttons,
  children,
}: HeadlineWithButtonsProps) {
  return (
    <HeadlineWrapper>
      <Typography variant="h5" component="h1">{children}</Typography>
      {buttons}
    </HeadlineWrapper>
  );
}
