import castArray from 'lodash/castArray';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import qs from 'qs';

const testingHost = 'api.snabble-testing.io';

const whichEnvironment = (location?: Location) => {
  if (!location) return 'testing';
  const { host } = location;
  if (host === 'admin.snabble.io') {
    return 'prod';
  }
  if (host === 'admin.snabble-staging.io') {
    return 'staging';
  }
  return 'testing';
};

const addHost = (location: Location, url?: string, newHost: string = 'api', forceHttps: boolean = true) => {
  if (url) {
    if (url.slice(0, 4) === 'http' || !url.startsWith('/') || !location.host) {
      return url;
    }
  }

  let host = '';
  if (!location.host.match(/snabble(-((testing)|(staging)))?\.io/)) {
    host = testingHost;
  } else {
    host = location.host;
  }

  const protocol = forceHttps ? 'https:' : location.protocol;

  host = host.replace(/^\w+\./, `${newHost}.`);
  return `${protocol}//${host}${url}`;
};

function elementsMatch<T>(array: Array<T>, other: Array<T>) {
  return isEqual(array.sort(), other.sort());
}

const failedStates = ['preconditionsNotMet', 'paymentFailed', 'userAborted', 'systemAborted'];

function extractQueries(queryString?: string) {
  if (!queryString) {
    return {};
  }

  let sanitized = queryString;
  if (sanitized.startsWith('?')) {
    sanitized = sanitized.slice(1);
  }

  const query = qs.parse(sanitized);
  if (query.includeAllStates) {
    query.state = '';
  } else {
    const states = castArray(query.state);

    if (elementsMatch(states, failedStates)) {
      query.state = 'failed';
    } else if (elementsMatch(states, ['final'])) {
      query.state = 'succeededNotTransferred';
    } else {
      query.state = 'succeeded';
    }
  }

  return query;
}

function toSearchQuery(query: any) {
  const q = { ...query };

  switch (q.state) {
    case '':
      q.state = [];
      q.includeAllStates = true;
      break;
    case 'succeededNotTransferred':
      q.state = ['final'];
      break;
    case 'failed':
      q.state = failedStates;
      break;
    default:
      q.state = [];
      break;
  }

  const filtered = pickBy(q, (value) => !isNull(value)
    && !isUndefined(value)
    && (!has(value, 'length') || value.length > 0));

  const str = qs.stringify(filtered, { arrayFormat: 'repeat' });
  if (!str) {
    return '';
  }

  return `?${str}`;
}

function safeToISOString(value?: string | Date) {
  if (value) {
    const valueDate = moment(value);
    if (valueDate.isValid()) {
      return valueDate.toISOString(true);
    }
  }
  return undefined;
}

export type Range = { from?: string | Date, to?: string | Date };

function toRangeQuery({ from, to }: Range) {
  return { from: safeToISOString(from), to: safeToISOString(to) };
}

function rangeFromQuery(search?: string, defaultRange?: Range) {
  const urlQueries = extractQueries(search);
  const fromValue = urlQueries.from as string || defaultRange?.from;
  const toValue = urlQueries.to as string || defaultRange?.to;
  return { from: safeToISOString(fromValue), to: safeToISOString(toValue) };
}

function toCustomerCardQuery(value: any) {
  switch (value) {
    case 'true':
    case true:
      return { withCustomerCard: 'true' };
    case 'false':
    case false:
      return { withCustomerCard: 'false' };
    default:
      return {};
  }
}

function customerCardFromQuery(search?: string, defaultValue = null) {
  const { withCustomerCard } = extractQueries(search);
  switch (withCustomerCard) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return defaultValue;
  }
}

function paymentMethodsFromQuery(search?: string) {
  const paymentMethods = extractQueries(search).paymentMethod;
  if (!paymentMethods) {
    return null;
  }

  if (Array.isArray(paymentMethods)) {
    return paymentMethods;
  }

  return [paymentMethods];
}

function toShopQuery(value: any) {
  if (!value) {
    return {};
  }

  return { shop: value };
}

function toPaymentMethodQuery<T>(methods: Array<T>) {
  if (!methods || !methods.length) {
    return {};
  }
  return { paymentMethod: methods };
}

function shopFromQuery(search?: string) {
  const { shop } = extractQueries(search);
  return shop;
}

const prependApiHost = addHost.bind(window, window.location);

export {
  addHost,
  prependApiHost,
  extractQueries,
  toRangeQuery,
  rangeFromQuery,
  toCustomerCardQuery,
  customerCardFromQuery,
  toSearchQuery,
  toShopQuery,
  shopFromQuery,
  paymentMethodsFromQuery,
  toPaymentMethodQuery,
  whichEnvironment,
};
