import React from 'react';
import SidebarMenuItemContent from './SidebarMenuItemContent';

type SidebarMenuItemProps = {
  linkText?: string,
  translationId?: string,
  icon?: React.ReactNode,
  linkTarget: string,
  disabled?: boolean,
};

const styles = () => ({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
});

export default function SidebarMenuItem({
  linkText,
  translationId,
  icon,
  linkTarget,
  disabled,
}: SidebarMenuItemProps) {
  const classes = styles();

  return (
    <a
      href={linkTarget}
      style={classes.link}
    >
      <SidebarMenuItemContent
        linkText={linkText}
        translationId={translationId}
        icon={icon}
        disabled={disabled}
      />
    </a>
  );
}
